import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import {SearchContext} from 'js/context/SearchContext';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SearchResults from 'js/containers/Pages/Search/SearchResults/SearchResults';
import SearchSidebar from 'js/containers/Pages/Search/SearchSidebar/SearchSidebar';

import classes from './Search.module.pcss';
import filterFetchData from './Filter/FilterFetchData';
import videoFetchData from './SearchResults/Entities/Entity/Video/VideoFetchData';
import manualFetchData from './SearchResults/Entities/Entity/Manual/ManualFetchData';
import courseFetchData from './SearchResults/Entities/Entity/Course/CourseFetchData';
import articleFetchData from './SearchResults/Entities/Entity/Article/ArticleFetchData';
import meetingFetchData from './SearchResults/Entities/Entity/Meeting/MeetingFetchData';
import podcastFetchData from './SearchResults/Entities/Entity/Podcast/PodcastFetchData';
import searchResultCountData from './SearchResults/SearchResultsCount/SearchResultsCountData';
import presentationFetchData from './SearchResults/Entities/Entity/Presentation/PresentationFetchData';

const Search = () => {
    const nodeRef = useRef(null);
    const scrollMidRef = useRef(null);
    const scrollEndRef = useRef(null);
    const [searchData, setSearchData] = useState([]);
    const [pagesCount, setPagesCount] = useState<number>(0);
    const [postsCount, setPostsCount] = useState<number>(0);
    const [load, setLoad] = useState<boolean>(false);
    const {
        apiPath,
        searchQuery,
        searchPage,
        setSearchPage
    } = useContext(CommonContext);
    const {
        filterEntitiesData,
        orderBy,
        filterRatingData,
        setRatingCounts,
        setEntitiesCounts,
        setSearchStatus,
        searchStatus,
        filterTaxData,
        setTaxCounts
    } = useContext(SearchContext);

    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);

    useEffect(() => {
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: 'Search'
            });
        })();
    }, [INIT_MIX_PANEL]);

    const pageChangeHandler = useCallback((page) => {
        setSearchPage(page);
        setSearchStatus(false);
    }, [setSearchPage, setSearchStatus]);

    useEffect(() => {
        if (!load) {
            return;
        }
        (async () => {
            await MixPanelWrapper.TrackEvent(
                'Search Results count',
                {
                    category: 'Search Results',
                    value: `${postsCount}`
                });
        })();
    }, [load, postsCount]);

    const setTaxCountsByPrevTaxVal = useCallback((data) => {
        const {
            suitableForCount,
            skillsCount,
            productCount,
            languageCount,
            articleTypeCount,
            courseTypeCount,
            eventTypeCount,
            videoTypeCount,
            podcastTypeCount,
            presentationTypeCount,
            manualTypeCount
        } = data?.search || [];

        const counts = {
            suitableFor: suitableForCount,
            skills: skillsCount,
            product: productCount,
            language: languageCount,
            articleType: articleTypeCount,
            courseType: courseTypeCount,
            eventType: eventTypeCount,
            videoType: videoTypeCount,
            podcastType: podcastTypeCount,
            presentationType: presentationTypeCount,
            manualType: manualTypeCount
        };

        if (
            filterTaxData?.articleType?.length ||
            filterTaxData?.courseType?.length ||
            filterTaxData?.eventType?.length ||
            filterTaxData?.presentationType?.length ||
            filterTaxData?.videoType?.length ||
            filterTaxData?.podcastType?.length ||
            filterTaxData?.manualType?.length > 0
        ) {
            counts.product = productCount;
        }

        if (filterTaxData?.productLegacy) {
            counts.skills = skillsCount;
        }

        if (filterTaxData?.skills) {
            counts.suitableFor = suitableForCount;
        }

        if (filterTaxData?.suitableFor) {
            counts.language = languageCount;
        }

        setTaxCounts(counts);
    }, [filterTaxData, setTaxCounts]);

    useEffect(() => {
        let isCanceled = false;

        if (!searchQuery || searchStatus) {
            return;
        }
        const dotsReg = new RegExp('[.\\,\\_]+', 'g');
        const bracketsReg = new RegExp('[^ \\w]+', 'g');
        let filteredQuery = searchQuery.replace(dotsReg, '');
        filteredQuery = filteredQuery.replace(bracketsReg, ' ');

        (async () => {
            const response = await fetch(`${apiPath}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `
					{
                        search {
                          ${filterFetchData(filterEntitiesData, filterTaxData, filterRatingData)}
                          searchResult(query: "${filteredQuery}", orderBy: "${orderBy}", page: ${searchPage}) {
                            ${courseFetchData}
                            ${articleFetchData}
                            ${manualFetchData}
                            ${meetingFetchData}
                            ${presentationFetchData}
                            ${videoFetchData}
                            ${podcastFetchData}
                          }
                          ${searchResultCountData}
                        }
                    }
				`
                })
            });
            if (isCanceled) {
                return;
            }
            const { data } = await response.json();
            setSearchData(data?.search?.searchResult);
            setPagesCount(data?.search?.pagesCount);
            setPostsCount(data?.search?.postsCount);
            setEntitiesCounts(data?.search?.entitiesCount);
            setRatingCounts(data?.search?.ratingCount);
            setTaxCountsByPrevTaxVal(data);

            setTaxCounts({
                suitableFor: data?.search?.suitableForCount,
                skills: data?.search?.skillsCount,
                productLegacy: data?.search?.productCount,
                language: data?.search?.languageCount,
                articleType: data?.search?.articleTypeCount,
                courseType: data?.search?.courseTypeCount,
                eventType: data?.search?.eventTypeCount,
                videoType: data?.search?.videoTypeCount,
                manualType: data?.search?.manualTypeCount,
                presentationType: data?.search?.presentationTypeCount,
                podcastType: data?.search?.podcastTypeCount
            });

            setSearchStatus(true);
            setLoad(true);
            scrollMidRef.current = false;
            scrollEndRef.current = false;
        })();
        return () => {
            isCanceled = true;
        };
    }, [
        setTaxCounts,
        setTaxCountsByPrevTaxVal,
        filterTaxData,
        searchQuery,
        apiPath,
        searchPage,
        searchStatus,
        filterEntitiesData,
        filterRatingData,
        orderBy,
        setEntitiesCounts,
        setRatingCounts,
        setSearchStatus
    ]);

    const setFilterSidebar = useMemo(() => {
        const searchQueryLength = searchQuery.length;
        const searchDataLength = searchData?.length;
        const {
            skills,
            suitableFor,
            videoType,
            eventType,
            manualType,
            podcastType,
            articleType,
            courseType,
            presentationType,
            productLegacy,
            language
        } = filterTaxData || {};

        const isFilterChecked:boolean =
            !! filterEntitiesData?.length
            || !!skills?.length
            || !!suitableFor?.length
            || !!videoType?.length
            || !!eventType?.length
            || !!filterRatingData?.length
            || !!manualType?.length
            || !!articleType?.length
            || !!courseType?.length
            || !!presentationType?.length
            || !!productLegacy?.length
            || !!language?.length
            || !!podcastType?.length;

        if (searchQueryLength > 0 && searchDataLength === 0) {
            if (isFilterChecked) {
                return <SearchSidebar nodeRef={nodeRef}/>;
            }
            return null;
        }
        return <SearchSidebar nodeRef={nodeRef}/>;
    }, [
        searchData,
        searchQuery,
        filterTaxData,
        filterRatingData,
        filterEntitiesData
    ]);

    const searchResults = useMemo(() => {
        const searchQueryLength = searchQuery.length;
        const searchDataLength = searchData?.length;

        return (
            <Container additionalClass={classes.SearchResultsWrap}>
                { setFilterSidebar }
                {(searchQueryLength > 0 && searchDataLength > 0) ? <SearchResults
                    data={searchData}
                    count={postsCount}
                    pages={pagesCount}
                    pageChange={pageChangeHandler}/> : searchStatus ?
                    <div className={classes.SearchResultsEmpty}>
                        <Heading text={`Sorry, there were no results for <strong>‘${searchQuery}’</strong>`} type={'h2'} />
                        <Heading text={'Check your spelling or try a more general term.'} type={'h5'} />
                    </div> : null
                }
            </Container>
        );
    }, [setFilterSidebar, pageChangeHandler, pagesCount, postsCount, searchData, searchQuery, searchStatus]);

    return (
        <div id={'Search'} className={classes.Search} ref={nodeRef}>
            <SeoHelmet title={'Search'} description={'Search'} canonical={'/search'}/>
            {searchResults}
        </div>
    );
};

export default Search;
