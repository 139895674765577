import React, {useCallback, useContext, useState} from 'react';
import Button from 'js/components/Common/Button/Button';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import {MyProfileContext} from 'js/context/MyProfileContext/MyProfileContext';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import classes from './SaveProfileInfo.module.pcss';
import {updateAvatarFetchData, updateAPIFetchData} from './fetchData';

const SaveProfileInfo = () => {
    const { preferences, interests } = useContext(MyProfileContext);
    const {addToastMessage} = useContext(ToastMessagesContext);
    const {activeAvatar} = useContext(CommonContext);
    const [activeSpinner, setActiveSpinner] = useState<boolean>(false);

    const saveAvatar = useFetchCallback({
        updateData: data => data?.viewer?.updateProfileAvatar,
        query: val => `${updateAvatarFetchData(val)}`,
        onStart: () => setActiveSpinner(true),
        onFailure: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
        },
        onError: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
        }
    });

    const saveAPIData = useFetchCallback({
        updateData: data => data?.viewer?.updateProfileAvatar,
        query: val => `${updateAPIFetchData(
            val,
            Number(preferences?.email?.status || false),
            Number(preferences?.messages?.status || false),
            Number(preferences?.notifications?.status || false),
            Number(preferences?.disableAll?.status || false)
        )}`,
        onSuccess: () => {
            setActiveSpinner(false);
            addToastMessage({
                type: 'success',
                key: 'saveProfileToasts'
            });
        },
        onFailure: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
        },
        onError: () => {
            addToastMessage({
                type: 'error',
                key: 'saveProfileToasts'
            });
        }
    });

    const saveButtonClickHandler = useCallback(async () => {
        await saveAvatar(activeAvatar);
        await saveAPIData(interests);
    }, [activeAvatar, interests, saveAPIData, saveAvatar]);

    return (
        <div className={classes.SaveProfileInfo}>
            <Button
                disabled={interests?.length === 0}
                spinnerType={'white'}
                withSpinner={activeSpinner}
                type={'button'}
                handler={saveButtonClickHandler}
                text={'Save'}/>
        </div>
    );
};

export default SaveProfileInfo;
