import imageFragment from 'js/graphqlFragments/ImageFragment';

export default () => {
    const a = 'AvatarOne';
    const b = 'AvatarTwo';
    const c = 'AvatarThree';
    const d = 'AvatarFour';
    return (
        `
    {
        themeGeneralSettings {
          Options {
            avatars {
              avatarOne {
                ...ImageFragment${a}
              }
              avatarTwo {
                ...ImageFragment${b}
              }
              avatarThree {
                ...ImageFragment${c}
              }
              avatarFour {
                ...ImageFragment${d}
              }
            }
          }
        }
    }
    ${imageFragment(`${a}`)}
    ${imageFragment(`${b}`)}
    ${imageFragment(`${c}`)}
    ${imageFragment(`${d}`)}
`
    );
};
