import React, {useCallback} from 'react';
import Label from 'js/components/Search/Label/Label';
import Views from 'js/components/Search/Views/Views';
import TagLabel from 'js/components/Common/Label/Label';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import ReadingTime from 'js/components/Search/ReadingTime/ReadingTime';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        link: string,
        readTime: number,
        type: 'articles',
        category: string,
        views: number,
        shortDescription: string
    },
    isPromoted: boolean
}

const Article = ({data, isPromoted}: Props) => {
    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Article page',
            {
                category: 'Search Results',
                value: data?.title
        });
    }, [data]);
    return (
        <div
            className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail type={'articles'} alt={data?.title}/>
            <div className={commonClasses.SearchResultItemInfo}>
                <Heading text={`${data?.title}`} type={'h5'} />
                <Description data={data?.shortDescription}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'articles'}/>
                    <TagLabel value={data?.category} additionalClass={commonClasses.TagLabel}/>
                    <ReadingTime data={data?.readTime} />
                    <Views data={data?.views} />
                </div>
                <div className={commonClasses.Wrap}>
                    <Button
                        buttonType={'ButtonUncolored'}
                        target={'_blank'}
                        link={data?.link}
                        type={'a'}
                        text={'Read Now'}
                        handler={sendMixPanelEvent} />
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </div>
        </div>
    );
};

export default Article;
