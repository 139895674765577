import React, {useState} from 'react';
import Label from 'js/components/Search/Label/Label';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import DateByTimezone from 'js/components/Common/DateByTimezone/DateByTimezone';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';
import EventRegistrationWrap from 'js/components/Common/EventRegistrationWrap/EventRegistrationWrap';

import classes from './Meeting.module.pcss';
import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        subtitle: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'meetings',
        eventType: 'Classroom' | 'Panel' | 'Conference',
        link: string,
        meetingID: string,
        meetingDateID: number,
        databaseId: number,
        initRegistrationHash: string,
        date: string,
        registrationButton: string,
        joinButton: string,
        viewButton: string
    },
    isPromoted: boolean
}

const Meeting = ({data, isPromoted}:Props) => {
    const {
        id,
        eventType,
        date,
        meetingDateID,
        meetingID,
        title,
        link,
        initRegistrationHash,
        featuredImage,
        subtitle,
        registrationButton,
        joinButton,
        viewButton
    } = data || {};
    const [registrationHash, setRegistrationHash] = useState<string | null>(initRegistrationHash);
    return (
        <div className={`${commonClasses.SearchResultItem} ${classes.Meeting} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} />
            <span className={commonClasses.SearchResultItemInfo}>
                <Heading text={`${title}`} type={'h5'} />
                <Description data={subtitle}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'meetings'}/>
                    <DateByTimezone className={commonClasses.SearchDate} date={data?.date} format={'DD MMMM YYYY, HH:mm '} />
                </div>
                <div className={commonClasses.Wrap}>
                    <EventRegistrationWrap
                        eventType={eventType}
                        startDate={date}
                        eventLink={link}
                        meetingID={meetingID}
                        meetingDateID={meetingDateID}
                        databaseId={id}
                        initialRegistrationHash={registrationHash}
                        setRegistrationHashCallback={setRegistrationHash}
                        buttons={{
                            viewEventButton: viewButton,
                            registerEventButton: registrationButton,
                            joinEventButton: joinButton
                        }}
                        title={title}
                        eventCategory={'Search Results'}>
                        <Button spinnerType={'blue'} buttonType={'ButtonUncolored'} />
                    </EventRegistrationWrap>
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </span>
        </div>
    );
};

export default Meeting;
