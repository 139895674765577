import React from 'react';


import Search from './Search';

const SearchWrap = () => (
    <Search />
);

export default SearchWrap;
