import React, {useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ExpandableSectionInfo from 'js/components/Common/ExpandableSection/ExpandableSectionInfo';
import ExpandableSectionHeading from 'js/components/Common/ExpandableSection/ExpandableSectionHeading';

import fetchData from './fetchData';
import classes from './ExpandableDisclaimer.module.pcss';

const ExpandableDisclaimer = ({pageId, nodeRef, additionalCondition = false}) => {
    const [disclaimerData, setDisclaimerData] = useState<{
        title: string,
        info: string
    }[]>([]);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            setDisclaimerData(data?.page?.EventsTemplate?.expandableDisclaimer);
        }
    });

    if (additionalCondition) {
        return null;
    }
    return (
        <DataValidator data={!!disclaimerData && !isLoading && !error}>
            <div className={classes.ExpandableDisclaimer}>
                <Container>
                    {disclaimerData?.map(item => (
                        <>
                            <ExpandableSectionHeading text={item?.title} nodeRef={nodeRef} />
                            <ExpandableSectionInfo data={item?.info} />
                        </>
                    ))}
                </Container>
            </div>
        </DataValidator>
    );
};

export default ExpandableDisclaimer;
