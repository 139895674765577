import React, {useContext} from 'react';
import {SearchContext} from 'js/context/SearchContext';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import Sorting from '../Sorting/Sorting';
import classes from './SearchResults.module.pcss';
import SearchPagination from './SearchPagination/SearchPagination';
import SearchResultsItem from './SearchResultsItem/SearchResultsItem';
import SearchResultsCount from './SearchResultsCount/SearchResultsCount';


const SearchResults = ({data, count, pages, pageChange}) => {
    const {searchQuery} = useContext(CommonContext);
    const {searchStatus} = useContext(SearchContext);

    return (
        <div id={'SearchResults'} className={classes.SearchResults}>
            <div className={classes.SearchResultsBoxWrap}>
                <SearchResultsCount
                    query={searchQuery}
                    count={count}
                    countPerPage={data?.length}
                    isActive={searchStatus}
                />
                <Sorting />
            </div>
            <DataValidator data={data?.length > 0}>
                <div className={classes.SearchResultsBox}>
                    <div className={`${classes.SearchResultItems} ${searchStatus ? classes.SearchResultItemsActive : ''}`}>
                        {data?.map(item =>
                            <SearchResultsItem
                                key={item?.id}
                                type={item?.type}
                                data={item}
                                isPromoted={item?.isPromoted}/>
                        )}
                    </div>
                    {pages > 0 ? <SearchPagination pages={pages} pageChange={pageChange} /> : null}
                </div>
            </DataValidator>
        </div>
    );
};

export default SearchResults;
