import React, {useCallback, useContext, useState} from 'react';
import Moment from 'react-moment';
import Image from 'js/components/Common/Image/Image';
import PopUp from 'js/components/Common/PopUp/PopUp';
import isEmptyObject from 'js/helpers/isEmptyObject';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlock} from 'js/components/Common/Image/ImageBlockDataInterface';
import {ToastMessagesContext} from 'js/context/ToastMessagesContext/ToastMessagesContext';

import fetchData from './fetchData';
import classes from './Avatar.module.pcss';

type AvatarsType = {
    avatarOne: ImageBlock,
    avatarTwo: ImageBlock,
    avatarThree: ImageBlock,
    avatarFour: ImageBlock
}

const Avatar = () => {
    const {activeAvatar, setActiveAvatar} = useContext(CommonContext);
    const avatarKeys = ['avatarOne', 'avatarTwo', 'avatarThree', 'avatarFour'];
    const [popupHeading, setPopupHeading] = useState<string>('');
    const [avatars, setAvatars] = useState<AvatarsType>(null);
    const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
    const {currentUser} = useContext(CommonContext);
    const {addToastMessage} = useContext(ToastMessagesContext);
    const {firstName, lastName, registeredDate} = currentUser || {};

    const { isLoading, error } = useFetchData({
        query: `${fetchData()}`,
        condition: true,
        updateData: (data) => {
            const result = data?.themeGeneralSettings?.Options;
            setPopupHeading(result?.headingAvatars);
            setAvatars(result?.avatars);
        }
    });

    const changeAvatarClickHandler = useCallback(() => {
        setPopUpTrigger(false);
    }, []);

    const avatarSelectItemClickHandler = useCallback((key) => {
        setActiveAvatar(key);
        addToastMessage({
            type: 'success',
            key: 'changeAvatarToasts'
        });
        setPopUpTrigger(false);
    }, [addToastMessage, setActiveAvatar]);

    return (
        <DataValidator data={!isLoading && !error && !!currentUser}>
            <section className={classes.Avatar}>
                <span className={classes.ProfileIcon}>Profile</span>
                <div className={classes.Wrap}>
                    <figure className={classes.Picture}>
                        {
                            isEmptyObject(avatars) ?
                                <Image
                                    mediaDetails={avatars[activeAvatar]?.mediaDetails}
                                    mediaItemUrl={avatars[activeAvatar]?.mediaItemUrl}
                                    size={'240x240'}
                                    alt={avatars[activeAvatar]?.altText} />
                                : null
                        }
                        <span onClick={() => setPopUpTrigger(true)} className={classes.ChangeAvatarButton} />
                    </figure>
                    <div className={classes.Info}>
                        <Heading
                            type={'h1'}
                            text={`${firstName} ${lastName}`} />
                        <div className={classes.LastLogged}>
                            <span>Last login: </span>
                            {registeredDate ? <Moment format={'DD/MM/YYYY'}>{registeredDate}</Moment> : ''}
                        </div>
                    </div>
                </div>
                <PopUp
                    additionalClass={classes.AvatarPopup}
                    type={'M'}
                    headingType={'h2'}
                    heading={popupHeading}
                    popUpTrigger={popUpTrigger}
                    popUpClose={changeAvatarClickHandler}>
                    <figure className={classes.AvatarSelect}>
                        {avatarKeys?.map((key) => {
                            if (!key || !isEmptyObject(avatars)) {
                                return null;
                            }
                            return (
                                <span
                                    key={key}
                                    onClick={() => avatarSelectItemClickHandler(key)}
                                    className={`${classes.AvatarSelectItem} ${activeAvatar === key ? classes.Active : ''}`}>
                                <Image
                                    mediaDetails={avatars[key]?.mediaDetails}
                                    mediaItemUrl={avatars[key]?.mediaItemUrl}
                                    size={'240x240'}
                                    alt={avatars[key]?.altText} />
                            </span>
                            );
                        })}

                    </figure>
                </PopUp>
            </section>
        </DataValidator>
    );
};

export default Avatar;
