import React, {useCallback, useMemo, useState} from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';

import classes from './EmbedVideo.module.pcss';

export interface EmbedVideoType {
	title: string,
	link: string,
	preview: ImageBlockDataInterface,
	customVideoSize?: {
		width: string,
		height: string
	},
	sendEventHandler: Function
}

const EmbedVideo = ({title, link, preview, sendEventHandler, customVideoSize = null}: EmbedVideoType) => {
	const [frameURL, setFrameURL] = useState<string>('');
	const bg = useMemo(() => (
		{
			backgroundImage: `url(${preview?.mediaItemUrl})`
		}
	), [preview]);
	const playClickHandler = useCallback(() => {
		setFrameURL(`${link}&autoplay=1`);
		sendEventHandler(title);
	}, [link, title, sendEventHandler]);

	const width = useMemo(() => customVideoSize?.width || '667', [customVideoSize]);
	const height = useMemo(() => customVideoSize?.height || '375', [customVideoSize]);

    return (
        <DataValidator data={link?.length > 0 && preview?.mediaItemUrl}>
			<div className={`${classes.EmbedVideo} ${frameURL?.length > 0 ? classes?.Active : ''}`} style={bg}>
				<iframe
					src={frameURL}
					width={width}
					height={height}
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen></iframe>
				<button className={classes.Play} onClick={playClickHandler} />
			</div>
		</DataValidator>
    );
};

export default EmbedVideo;
