import React, {useCallback} from 'react';
import Label from 'js/components/Search/Label/Label';
import Views from 'js/components/Search/Views/Views';
import Button from 'js/components/Common/Button/Button';
import TagLabel from 'js/components/Common/Label/Label';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import WatchTime from 'js/components/Search/WarchTime/WatchTime';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import MediaDuration from 'js/components/Common/MediaDuration/MediaDuration';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'videos',
        product: string,
        description: string,
        videoDuration: string,
        publishingDate: string,
        watchTime: string,
        views: number,
        link: string
    },
    isPromoted: boolean
}

const Video = ({data, isPromoted}: Props) => {
    const {
        title,
        videoDuration,
        featuredImage,
        description,
        link,
        watchTime,
        views,
        type,
        product
    } = data || {};

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Video page',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);
    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} type={'videos'} alt={title} />
            <span className={commonClasses.SearchResultItemInfo}>
                <Heading text={title} type={'h5'}/>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={type}/>
                    <TagLabel value={product} additionalClass={commonClasses.TagLabel}/>
                    <MediaDuration data={videoDuration} />
                    <WatchTime data={watchTime} />
                    <Views data={views} />
                </div>
                <div className={commonClasses.Wrap}>
                    <Button
                        buttonType={'ButtonUncolored'}
                        link={link}
                        target={'_blank'}
                        type={'a'}
                        text={'Watch Now'}
                        handler={sendMixPanelEvent} />
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </span>
        </div>
    );
};

export default Video;
