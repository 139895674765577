import React, {useCallback, useState} from 'react';
import Label from 'js/components/Search/Label/Label';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';
import Downloads from 'js/components/Common/ActivityCounter/Downloads';
import PublishingDate from 'js/components/Common/PublishingDate/PublishingDate';
import Button from 'js/components/Common/Button/Button';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import {updateDownloadsQueryManual} from './ManualFetchData';

export interface Props {
    data: {
        id: number,
        file: string,
        title: string,
        type: 'manuals',
        language: string,
        thumbnail: string,
        downloads: number,
        description: string,
        publishingDate: string
    },
    isPromoted: boolean
}

const Manual = ({data, isPromoted}: Props) => {
    const {title, description, publishingDate, downloads, id, file, thumbnail} = data || {};
    const [itemDownloads, setItemDownloads] = useState<number>(downloads);

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Download Manual',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    const fileDownloadHandler = useFetchCallback({
        updateData: val => val?.manualsSingle?.updateDownloads,
        query: () => updateDownloadsQueryManual(id),
        onStart: async () => {
            const element = document.createElement('a');
            element.setAttribute('href', file);
            const ext = file.split('.').pop();
            element.setAttribute('download', `${title}.${ext}`);

            element.style.display = 'block';
            element.click();
            setItemDownloads(prev => prev + 1);
            await sendMixPanelEvent();
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });
    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={{
                url: thumbnail,
                altText: title
            }} alt={title}/>
            <span className={commonClasses.SearchResultItemInfo}>
                <Heading text={`${title}`} type={'h5'}/>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'manuals'}/>
                    <Downloads count={itemDownloads}/>
		            <PublishingDate data={publishingDate}/>
                </div>
                <div className={commonClasses.Wrap}>
                    <Button
                        buttonType={'ButtonUncolored'}
                        type={'button'}
                        text={'Download'}
                        handler={fileDownloadHandler} />
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </span>
        </div>
    );
};

export default Manual;
