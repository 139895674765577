import React, {useEffect, useMemo} from 'react';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Container from 'js/components/Common/Container/Container';
import SaveProfileInfo from 'js/containers/Pages/Profile/SaveProfileInfo/SaveProfileInfo';

import Avatar from './Avatar/Avatar';
import classes from './Profile.module.pcss';
import Interests from './Interests/Interests';
import Information from './Information/Information';
import Preferences from './Preferences/Preferences';

const Profile = () => {
    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);
    useEffect(() => {
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: 'My Profile'
            });
        })();
    }, [INIT_MIX_PANEL]);
    return (
        <section className={classes.Profile}>
            <SeoHelmet title={'My Profile'} description={'My Profile'} canonical={'/profile'}/>
            <Container additionalClass={classes.ProfileWrap}>
                <Avatar/>
                <Information/>
                <Preferences/>
                <Interests/>
                <SaveProfileInfo/>
            </Container>
        </section>
    );
};

export default Profile;
