import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import React, {useCallback, useContext, useMemo} from 'react';
import Button from 'js/components/Common/Button/Button';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import DateByTimezone from 'js/components/Common/DateByTimezone/DateByTimezone';
import {NotificationsContext} from 'js/context/NotificationsContext/NotificationsContext';
import EventRegistrationWrap from 'js/components/Common/EventRegistrationWrap/EventRegistrationWrap';

import updateSingleNotificationFetch from './fetchData';
import classes from './NotificationItem.module.pcss';
import setIconByType from '../NotificationItem/Helpers';
import {NotificationDataType} from '../NotificationsDataInterface';

export interface Props {
    data : NotificationDataType,
    closeFrame: Function,
    eventButtons: {
        registerEventButton: string,
        joinEventButton: string,
        viewEventButton: string
    }
}

const NotificationItem = ({data, closeFrame, eventButtons}: Props) => {
    const {id, type, link, time, title, status, description, eventData, img} = data || {};
    const {setStatus, notifications, customNotifications, setBlockUpdateTimer} = useContext(NotificationsContext);

    const updateNotificationRequestCallback = useFetchCallback({
        updateData: () => setBlockUpdateTimer(false),
        query: (requestType: 'delete' | 'change') => `${updateSingleNotificationFetch(id, type, requestType)}`,
        onSuccess: () => setBlockUpdateTimer(false),
        onFailure: () => setBlockUpdateTimer(false),
        onError: () => setBlockUpdateTimer(false)
    });

    const clickHandler = useCallback((e) => {
        const isDeleteButton = e?.target?.hasAttribute('data-delete-item');
        const isHourReminder = e?.currentTarget?.hasAttribute('data-hour-reminder');

        if (isDeleteButton || isHourReminder) {
            return;
        }

        closeFrame();
        setBlockUpdateTimer(true);
        if (status === 'read') {
            return;
        }
        setStatus(id, 'read', type === 'custom');
        updateNotificationRequestCallback('change');
    }, [
        id,
        type,
        status,
        setStatus,
        closeFrame,
        setBlockUpdateTimer,
        updateNotificationRequestCallback
    ]);

    const removeHandler = useCallback((e) => {
        e.preventDefault();
        setBlockUpdateTimer(true);
        setStatus(id, 'removed', false);
        const availableCustomNotification = customNotifications.find(element => element?.status !== 'removed');
        const availableAGNotifications = notifications.find(element => element?.status !== 'removed');

        if (!availableCustomNotification && !availableAGNotifications) {
            closeFrame();
        }

        updateNotificationRequestCallback('delete');
    }, [
        id,
        setStatus,
        closeFrame,
        notifications,
        setBlockUpdateTimer,
        customNotifications,
        updateNotificationRequestCallback
    ]);

    const content = useMemo(() => {
        let reminderType:'hour' | 'tomorrow' | '' = '';
        if (type === 'liveEventReminderHour') {
            reminderType = 'hour';
        }
        if (type === 'liveEventReminderTomorrow') {
            reminderType = 'tomorrow';
        }
        return (
            <>
                <figure className={classes.Image}>
                    {setIconByType(type, img)}
                </figure>
                <div className={classes.Info}>
                    <strong className={classes.Title}>{parse(title || '')}</strong>
                    <div className={classes.Description}>{parse(description || '')}</div>
                    {type === 'liveEventReminderHour' ?
                        <EventRegistrationWrap
                            meetingID={eventData?.dbId}
                            meetingDateID={eventData?.dateId}
                            databaseId={id}
                            eventLink={eventData?.link}
                            eventType={eventData?.type}
                            initialRegistrationHash={eventData?.registrationHash}
                            additionalHandlerCallback={() => {
                                closeFrame();
                                setStatus(id, 'read', false);
                                updateNotificationRequestCallback('change');
                            }}
                            startDate={eventData?.date}
                            buttons={eventButtons}
                            title={title}
                            eventCategory={'Notifications'}
                            setRegistrationHashCallback={() => {}}
                        >
                            <Button />
                        </EventRegistrationWrap> : null
                    }
                    <DateByTimezone className={classes.Time} fromNow={true} date={time} reminder={reminderType} />
                </div>
            </>
        );
    }, [
        id,
        img,
        type,
        time,
        title,
        setStatus,
        eventData,
        closeFrame,
        description,
        eventButtons,
        updateNotificationRequestCallback
    ]);

    const additionalClasses = useMemo(() => {
        const statusClass = status === 'unread' ? classes.Unread : '';
        const customItemClass = type === 'custom' ? classes.Custom : '';
        return `${statusClass} ${customItemClass}`;
    }, [status, type]);

    const item = useMemo(() => {
        if (type === 'custom') {
            return (
                <a
                    key={id}
                    href={link}
                    target={'_blank'}
                    rel={'nofollow noopener noreferrer'}
                    onClick={clickHandler}
                    className={`${classes.NotificationItem} NotificationAnimation ${additionalClasses}`}>{content}</a>
            );
        }

        if (type === 'liveEventReminderHour') {
            return (
                <span
                    key={id}
                    data-hour-reminder={true}
                    onClick={clickHandler}
                    className={`${classes.NotificationItem} NotificationAnimation ${additionalClasses}`}>
                {content}<span data-delete-item={true} onClick={removeHandler} className={classes.Close} /></span>
            );
        }

        if (type === 'course') {
            return (
                <a
                    key={id}
                    href={link}
                    onClick={clickHandler}
                    className={`${classes.NotificationItem} NotificationAnimation ${additionalClasses}`}>
                {content}<span data-delete-item={true} onClick={removeHandler} className={classes.Close} /></a>
            );
        }

        return (
            <Link
                key={id}
                onClick={clickHandler}
                to={link}
                className={`${classes.NotificationItem} NotificationAnimation ${additionalClasses}`}>
                {content}<span
                data-delete-item={true}
                onClick={removeHandler}
                className={classes.Close} /></Link>
        );
    }, [type, id, clickHandler, link, additionalClasses, content, removeHandler]);

    return (
        <CSSTransition
            in={status === 'removed'}
            timeout={200}
            appear
            classNames='NotificationAnimation'
        >{item}
        </CSSTransition>
    );
};

export default NotificationItem;
