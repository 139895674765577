export default taxonomyName => (
    `
    {
        ${taxonomyName}(where: {orderby: TERM_ID}, first: 50) {
          nodes {
            termTaxonomyId
            name
            slug
          }
        }
  }
`
);
