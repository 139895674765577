import React, {useContext, useMemo} from 'react';
import {SearchContext} from 'js/context/SearchContext';
import Heading from 'js/components/Common/Heading/Heading';

import Filter from '../Filter/Filter';
import classes from './SearchSidebar.module.pcss';
import FilterReset from '../Filter/FilterReset/FilterReset';


const SearchSidebar = ({nodeRef}) => {
    const {filterTaxData} = useContext(SearchContext);
    const {
        eventType,
        videoType,
        manualType,
        courseType,
        podcastType,
        articleType,
        presentationType
    } = useMemo(() => filterTaxData || null, [filterTaxData]);
    const showFilterResetButton = useMemo(() =>
            eventType?.length > 0 ||
            videoType?.length > 0 ||
            manualType?.length > 0 ||
            courseType?.length > 0 ||
            podcastType?.length > 0 ||
            articleType?.length > 0 ||
            presentationType?.length > 0
        , [
            eventType,
            videoType,
            manualType,
            courseType,
            podcastType,
            articleType,
            presentationType
        ]);
    return (
        <aside className={classes.SearchSidebar}>
            <div className={classes.Wrap}>
                <Heading text={'Filters'} type={'h4'} />
                {showFilterResetButton ? <FilterReset nodeRef={nodeRef} /> : null}
            </div>
            <Filter />
        </aside>
    );
};

export default SearchSidebar;
