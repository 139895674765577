import {FilterTaxDataType} from 'js/context/SearchContext';

const filterFetchData = (entities, filterTax:FilterTaxDataType, rating) => {
        const getRatingQuery = () => {
                if (rating?.length === 0) {
                        return '';
                }
                return `fields(rating: "${rating}") { result }`;
        };
        const setSingleTaxQuery = (tax, data) => {
                if (data?.length === 0) {
                        return '';
                }
                return `${tax}: "${data}",`;
        };
        const getTaxQuery = () => {
                const {
                        skills,
                        productLegacy,
                        language,
                        eventType,
                        videoType,
                        courseType,
                        suitableFor,
                        manualType,
                        articleType,
                        podcastType,
                        presentationType
                } = filterTax || {};
                const suitableForData = setSingleTaxQuery('suitableFor', suitableFor);
                const skillsData = setSingleTaxQuery('skills', skills);
                const productData = setSingleTaxQuery('productLegacy', productLegacy);
                const languageData = setSingleTaxQuery('language', language);
                const articleTypeData = setSingleTaxQuery('articleType', articleType);
                const eventTypeData = setSingleTaxQuery('eventType', eventType);
                const videoTypeData = setSingleTaxQuery('videoType', videoType);
                const courseTypeData = setSingleTaxQuery('courseType', courseType);
                const manualTypeData = setSingleTaxQuery('manualType', manualType);
                const podcastTypeData = setSingleTaxQuery('podcastType', podcastType);
                const presentationTypeData = setSingleTaxQuery('presentationType', presentationType);
                if (
                    !suitableForData &&
                    !skillsData && !productData &&
                    !languageData &&
                    !articleTypeData &&
                    !eventTypeData &&
                    !videoTypeData &&
                    !courseTypeData &&
                    !manualTypeData &&
                    !podcastTypeData &&
                    !presentationTypeData
                ) {
                        return '';
                }
                return (`
                taxonomies(
                ${suitableForData} 
                ${skillsData} 
                ${productData} 
                ${languageData} 
                ${articleTypeData} 
                ${eventTypeData} 
                ${videoTypeData} 
                ${courseTypeData} 
                ${manualTypeData} 
                ${podcastTypeData} 
                ${presentationTypeData}) 
                { result }
                `);
        };
        return (
            `
        searchFilter {
            entity(value: "${entities}") {
                result
             }
             ${getRatingQuery()}
             ${getTaxQuery()}
        }
        `
        );
};

export default filterFetchData;
