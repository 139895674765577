import React, {useCallback} from 'react';
import Label from 'js/components/Search/Label/Label';
import Rating from 'js/components/Search/Rating/Rating';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import MediaDuration from 'js/components/Common/MediaDuration/MediaDuration';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';

export interface Props {
    data: {
        id: number,
        title: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'podcasts',
        description: string,
        language: string,
        podcastDuration: string,
        publishingDate: string,
        rating: string,
        link: string
    },
    isPromoted: boolean
}

const Podcast = ({data, isPromoted}: Props) => {
    const {
        title,
        podcastDuration,
        featuredImage,
        description,
        link,
        rating,
        type
    } = data || {};
    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Open Podcast page',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={featuredImage} alt={title} />
            <span className={commonClasses.SearchResultItemInfo}>
                <Heading text={title} type={'h5'}/>
                <Description data={description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={type}/>
		            <MediaDuration data={podcastDuration} />
                    <Rating rating={rating}/>
                </div>
                <div className={commonClasses.Wrap}>
                    <Button
                        buttonType={'ButtonUncolored'}
                        link={link}
                        target={'_blank'}
                        type={'a'}
                        text={'Listen Now'}
                        handler={sendMixPanelEvent} />
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </span>
        </div>
    );
};

export default Podcast;
