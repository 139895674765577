import React, { useState } from 'react';
import parse from 'html-react-parser';
import PopUp from 'js/components/Common/PopUp/PopUp';
import Button from 'js/components/Common/Button/Button';

import classes from './NavigationItemPopUp.module.pcss';

export interface Props {
	link: string,
	label: string,
	title: string,
	popupInfo: string,
	buttonText: string,
	type: 'S' | 'M' | 'L' | 'XL'
}

const NavigationItemPopUp = ({ popupInfo, label, title, type, link = '', buttonText = '' }: Props) => {
	const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
	return (
		<div className={classes.NavigationItemPopUp}>
				<span
					className={`${classes.NavigationItemPopUpLink} emptyLink`}
					onClick={() => setPopUpTrigger(true)}>{label}</span>
			<PopUp
				type={type}
				heading={title}
				popUpTrigger={popUpTrigger}
				popUpClose={() => setPopUpTrigger(false)}>
				{parse(popupInfo)}
				{buttonText && link &&
					<div className={classes.ButtonWrap}>
						<Button
							link={link}
							type={'a'}
							text={buttonText}
							handler={() => setPopUpTrigger(false)}
							target={true} />
					</div>}
			</PopUp>
		</div>
	);
};

export default NavigationItemPopUp;
