import React from 'react';
import parse from 'html-react-parser';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';

import Checkboxes from '../Checkboxes/Checkboxes';
import classes from '../CookiePolicyPopup.module.pcss';

export type CookieDescriptionsType = {
    analyticsCookiesInfo: string,
    analyticsCookiesTitle: string,
    essentialCookiesInfo: string,
    essentialCookiesTitle: string
}
interface Props {
    title: string,
    info: string,
    checkboxHandler: Function,
    confirmChoiceHandler: Function,
    setAllCookiesHandler: Function,
    descriptions: CookieDescriptionsType
}
const SecondStage = (
    {title, info, setAllCookiesHandler, checkboxHandler, descriptions, confirmChoiceHandler}:Props) => (
    <div className={`${classes.Content} ${classes.SecondStage}`}>
        <div className={classes.Heading}>
            <Heading text={title} type={'h5'}/>
        </div>
        <div className={classes.Info}>
            {parse(info || '')}
        </div>
        <Checkboxes sendDataToParent={checkboxHandler} descriptions={descriptions}/>
        <div className={classes.ButtonsWrap}>
            <Button
                type={'button'}
                buttonColorType={'Pink'}
                buttonType={'ButtonUncolored'}
                handler={() => {
                    confirmChoiceHandler();
                }}
                text={'Confirm my choices'}/>
            <Button
                type={'button'}
                buttonColorType={'Pink'}
                buttonType={'ButtonColored'}
                handler={() => {
                    setAllCookiesHandler(true);
                }}
                text={'Accept all cookies'}/>
            <Button
                type={'button'}
                buttonColorType={'Violet'}
                buttonType={'ButtonUncolored'}
                handler={() => {
                    setAllCookiesHandler(false);
                }}
                text={'Decline all optional cookies'}/>
        </div>
    </div>
);

export default SecondStage;
