import React, {useContext, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

import fetchData from './fetchData';

export interface Props {
    postID: number,
    children: any
}

const ResourceVisibilityValidator = (props:Props) => {
    const {postID, children} = props || {};
    const [loaded, setLoaded] = useState<boolean>(false);
    const {apiPath} = useContext(CommonContext);

    const { isLoading, error } = useFetchData({
        query: fetchData(postID),
        condition: !!postID,
        updateData: (data, isLoadingData) => {
            if (isLoadingData) {
                return;
            }
            if (!data?.resourceVisibility?.checkResourceVisibility) {
                setLoaded(false);
                return window.location.href = `${apiPath}?hiddenResource=true`;
            }
            setLoaded(true);
        }
    });

    return loaded && !isLoading && !error ? children : <></>;
};

export default ResourceVisibilityValidator;
