import React, {useContext} from 'react';
import {SearchContext} from 'js/context/SearchContext';
import useSearchReset from 'js/hooks/useSearchReset/useSearchReset';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

import classes from './FilterReset.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

const FilterReset = (nodeRef) => {
    const resetClickHandler = useSearchReset(nodeRef);
    const {searchStatus} = useContext(SearchContext);
    const {searchQuery} = useContext(CommonContext);

    return (
        <button
            onClick={async () => {
                resetClickHandler();
                await MixPanelWrapper.TrackEvent(
                    'Reset search filter',
                    {
                        value: '',
                        category: 'Search Filters'
                    }
                );
            }}
            className={`${classes.FilterReset} ${searchStatus || searchQuery.length === 0 ? classes.FilterResetActive : ''}`}>Clear All</button>
    );
};

export default FilterReset;
