import React, {useContext, useMemo} from 'react';
import {SearchContext} from 'js/context/SearchContext';

import classes from './Filter.module.pcss';
import FilterItemSection from './FilterItemSection/FilterItemSection';


const Filter = () => {
        const {
            filterEntitiesData,
            filterRatingData,
            filterTaxData
        } = useContext(SearchContext);
        const {
            suitableFor,
            skills,
            productLegacy,
            articleType,
            podcastType,
            presentationType,
            manualType,
            eventType,
            videoType,
            courseType
        } = useMemo(() => filterTaxData || null, [filterTaxData]);

        const productsShowCondition = useMemo(() => (
            eventType?.length > 0 ||
            podcastType?.length > 0 ||
            presentationType?.length > 0 ||
            manualType?.length > 0 ||
            articleType?.length > 0 ||
            courseType?.length > 0 ||
            videoType?.length > 0
        ), [
            articleType,
            courseType,
            eventType,
            manualType,
            podcastType,
            presentationType,
            videoType
        ]);

    const skillsShowCondition = useMemo(() => productsShowCondition && productLegacy?.length > 0,
        [productLegacy, productsShowCondition]);

    const suitableForShowCondition = useMemo(() => skillsShowCondition && skills?.length > 0,
        [skills, skillsShowCondition]);

    const ratingShowCondition = useMemo(() =>
            suitableForShowCondition && (filterEntitiesData === 'videos' || filterEntitiesData === 'podcasts') &&
            suitableFor?.length > 0
        , [filterEntitiesData, suitableFor, suitableForShowCondition]);

    const languageShowCondition = useMemo(() => {
        if ((filterEntitiesData === 'videos' || filterEntitiesData === 'podcasts')) {
            return ratingShowCondition && filterRatingData?.length > 0;
        }
        return suitableForShowCondition && suitableFor?.length > 0;
        },
        [suitableFor, filterEntitiesData, suitableForShowCondition, ratingShowCondition, filterRatingData]);

        return (
            <div className={classes.Filter} data-search-filter={true}>
                <FilterItemSection
                    type={'entities'}
                    heading={'Content Type'}
                    />
                {filterEntitiesData === 'articles' ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'articleType'}
                    heading={'Article Type'}
                    /> : null}
                {filterEntitiesData === 'meetings' ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'eventType'}
                    heading={'Event Type'}
                   /> : null}
                {filterEntitiesData === 'presentations' ?
                    <FilterItemSection
                        type={'taxonomy'}
                        taxonomyName={'presentationType'}
                        heading={'Presentation Type'}
                    /> : null}
                {filterEntitiesData === 'videos' ?
                    <FilterItemSection
                        type={'taxonomy'}
                        taxonomyName={'videoType'}
                        heading={'Video Type'}
                    /> : null}
                {filterEntitiesData === 'courses' ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'courseType'}
                    heading={'Course Type'}
                    /> : null}
                {filterEntitiesData === 'manuals' ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'manualType'}
                    heading={'Manual Type'}
                    /> : null}
                {filterEntitiesData === 'podcasts' ?
                    <FilterItemSection
                        type={'taxonomy'}
                        taxonomyName={'podcastType'}
                        heading={'Podcast Type'}
                    /> : null}
                { productsShowCondition ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'productLegacy'}
                    heading={'Product'}
                    /> : null }
                { skillsShowCondition ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'skills'}
                    heading={'Topic'}
                    /> : null }
                { suitableForShowCondition ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'suitableFor'}
                    heading={'Suitable For'}
                    /> : null }
                { ratingShowCondition ?
                <FilterItemSection
                    type={'rating'}
                    heading={'Ratings'}
                    /> : null}
                { languageShowCondition ?
                <FilterItemSection
                    type={'taxonomy'}
                    taxonomyName={'language'}
                    heading={'Language'}
                    /> : null }
            </div>
        );
};

export default Filter;
