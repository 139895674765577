import React, {useContext, useMemo, useState} from 'react';
import ReactPlayer from 'react-player';
import { CommonContext } from 'js/context/CommonContext/CommonContext';

import classes from './PromoBoxItemBackground.module.pcss';
import { PromoDataBoxInterface } from '../../PromoBoxBlock/PromoBoxDataInterface';

export interface Props {
	item: PromoDataBoxInterface,
	first: boolean,
	previous: boolean,
	active: boolean,
	zIndex: number
}

const PromoBoxItemBackground = ({ item, active, previous, first, zIndex }: Props) => {
	const commonContext = useContext(CommonContext);
	const [disableFirst, setDisableFirst] = useState<boolean>(first);

	const resultZIndex = useMemo(() => {
		let index = zIndex;
		if (active) {
			index = 15;
		} else if (previous) {
			index = 10;
		}
		return index;
	}, [active, previous, zIndex]);

	const url = useMemo(() => {
		if (item && item?.slideBackground) {
			return item?.slideBackground?.mediaItemUrl;
		}
		return '';
	}, [item]);

	return (
		<div
			onAnimationEnd={() => setDisableFirst(false)}
			style={{ zIndex: resultZIndex, minHeight: `calc(100vh - ${commonContext.headerHeight}px)` }}
			className={
				`${classes.PromoBoxItemBackground} ${active ? classes.dynamicPromoBoxItemBackground : ''} ${disableFirst ? classes.DisableFirst : ''}`
			}>
			<ReactPlayer
				className={`${classes.PromoBoxItemBackgroundPlayer}`}
				url={url}
				config={{
					file: {
						forceVideo: false
					}
				}}
				muted={true}
				playing={true}
				loop={true}
				width='100%'
				height='100%'
			/>
		</div>);
};


export default PromoBoxItemBackground;
