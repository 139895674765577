import React, {useRef, useMemo, useCallback} from 'react';

import classes from './Input.module.pcss';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {InputDataInterface} from './InputDataInterface';
import parse from 'html-react-parser';

const Input = ({
                   id,
                   max,
                   min,
                   slug,
                   type,
                   name,
                   blur,
                   value,
                   click,
                   addRef,
                   onKeyUp,
                   checked,
                   required,
                   onChange,
                   disabled,
                   labelText,
                   maxLength,
                   minLength,
                   validation,
                   placeholder,
                   autoComplete,
                   defaultValue,
                   confirmField,
                   floatingLabel,
                   withLabel = false,
                   withMargin = false,
                   additionalClass = '',
                   slideCheckboxType = 'Small',
                   slideCheckboxColorType = 'Blue',
                   slideCheckbox = false,
                   customStylesClass = ''
               }: InputDataInterface) => {
    const inputRef = useRef(null);

    const convertToSlug = useCallback(str => (
        str.toLowerCase()
            .replace(/ /g, '_')
            .replace(/[^\w-]+/g, '')
    ), []);

    const changeTextInputHandler = useCallback((e) => {
        onChange?.(e);
    }, [onChange]);

    const textClassName = useMemo(() => {
        let str = `${customStylesClass || classes.Input}`;
        str += ` ${additionalClass}`;
        str += ` ${withMargin ? classes.withMargin : ''}`;
        return str;
    }, [additionalClass, customStylesClass, withMargin]);

    const radioCheckBoxClassName = useMemo(() => {
        let radioCheckBoxClass = `${
            customStylesClass ||
            (type === 'checkbox' && slideCheckbox ? classes.SlideCheckbox : '') ||
            (type === 'checkbox' && !slideCheckbox ? classes.CheckBox : '') ||
            (type === 'radio' ? classes.Radio : '')
        }`;
        radioCheckBoxClass += ` ${additionalClass}`;
        radioCheckBoxClass += ` ${withMargin ? classes.withMargin : ''}`;
        radioCheckBoxClass += ` ${slideCheckboxType ? classes[slideCheckboxType] : ''}`;
        radioCheckBoxClass += ` ${slideCheckboxColorType ? classes[slideCheckboxColorType] : ''}`;

        return radioCheckBoxClass;
    }, [
        type,
        slideCheckbox,
        slideCheckboxType,
        slideCheckboxColorType,
        customStylesClass,
        additionalClass,
        withMargin
    ]);

    switch (type) {
        case 'text' :
        case 'tel' :
        case 'search' :
        case 'number' :
        case 'password' :
        case 'email' :
            return (
                <div
                    data-confirm-field={confirmField}
                    className={`${textClassName} ${validation?.errorMessage ? classes.Error : ''}`}>
                    <input
                        className={value ? classes.NotEmpty : ''}
                        type={type}
                        id={id}
                        required={required}
                        placeholder={placeholder}
                        min={min}
                        max={max}
                        minLength={minLength}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                        disabled={disabled}
                        value={value}
                        name={name}
                        onChange={changeTextInputHandler}
                        onBlur={blur}
                        ref={addRef || inputRef}
                        onKeyUp={onKeyUp}
                    />
                    {withLabel ?
                        <label htmlFor={`${slug}_${id}`}>{name}</label>
                        : null}
                    {floatingLabel ?
                        <label className={classes.FloatingLabel} htmlFor={`${slug}_${id}`}>{floatingLabel}</label>
                        : null}
                    {validation?.errorMessage && <ErrorMessage errorMessage={validation?.errorMessage} />}
                </div>
            );
        case 'reset' : return <input type="reset" value={name} onClick={click}/>;
        case 'radio' :
        case 'checkbox' : {
            return (
                <div
                    className={`${radioCheckBoxClassName} ${validation?.errorMessage ? classes.Error : ''}`}
                    data-default-value={defaultValue}>
                    <input
                        id={`${convertToSlug(slug)}_${id}`}
                        name={slug}
                        type={type}
                        checked={checked}
                        onChange={onChange}
                        ref={addRef}
                        disabled={disabled}
                    />
                    {withLabel ?
                        <label htmlFor={`${convertToSlug(slug)}_${id}`}>
                            {typeof labelText === 'string' ? parse(labelText || '') : labelText}
                            {slideCheckboxType === 'Big' ? <span className={classes.SlideCheckBoxState}>{checked ? 'On' : 'Off'}</span> : null}
                        </label> : null}
                    {validation?.errorMessage && <ErrorMessage errorMessage={validation?.errorMessage} />}
                </div>
            );
        }
        default : return null;
    }
};

export default Input;

