import React, {useCallback, useContext, useMemo} from 'react';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import {SearchContext} from 'js/context/SearchContext';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

import classes from './Sorting.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

const Sorting = () => {
    const {setOrderBy, orderBy, setSearchStatus, searchStatus} = useContext(SearchContext);
    const {searchQuery} = useContext(CommonContext);

    const options = useMemo(() => [
        { value: 'mostRelevant', label: 'Most Relevant' },
        { value: 'highestRated', label: 'Highest Rated'},
        { value: 'type', label: 'Type'},
        { value: 'newest', label: 'Newest'},
        { value: 'views', label: 'Most Views'}
    ], []);

    const sendMixPanelEvent = useCallback(async (value: any) => {
        await MixPanelWrapper.TrackEvent(
            'Search Sorting',
            {
                category: 'Search Filters',
                value: `${value}`
            });
    }, []);

    return (
        <div data-search-sorting={true} className={`${classes.Sorting} ${searchStatus || searchQuery.length === 0 ? classes.SortingActive : ''}`}>
            <Dropdown
                className={classes.SortingDropdown}
                controlClassName={classes.SortingDropdownControl}
                arrowClassName={classes.SortingDropdownArrow}
                menuClassName={classes.SortingDropdownMenu}
                placeholderClassName={classes.SortingDropdownPlaceholder}
                options={options}
                onChange={async (e) => {
                    if (orderBy === e?.value) {
                        return;
                    }
                    setOrderBy(e?.value);
                    await sendMixPanelEvent(e?.label);
                    if (searchQuery) {
                        setSearchStatus(false);
                    }
                }}
                value={orderBy} placeholder={orderBy} />
        </div>
    );
};

export default Sorting;
