import {useEffect, useState} from 'react';

import userFetch from './fetchData';

export type UserType = {
    id: number,
    uuid: string,
    email: string,
    company: string,
    username: string,
    position: string,
    lastName: string,
    firstName: string,
    userAvatar: string,
    registeredDate: string,
    promotionalEmails: boolean,
    playtechUser: boolean,
    hasProducts: boolean,
    promotionalMessages: boolean,
    disableAllMarketing: boolean,
    browserNotifications: boolean
}

export const userInitialData = {
    id: null,
    uuid: '',
    email: '',
    company: '',
    position: '',
    username: '',
    lastName: '',
    firstName: '',
    registeredDate: '',
    playtechUser: false,
    hasProducts: false,
    userAvatar: 'avatarOne',
    promotionalEmails: false,
    disableAllMarketing: false,
    promotionalMessages: false,
    browserNotifications: false
};

const useGetUser = (path) => {
    const [currentUser, setCurrentUser] = useState<UserType>(userInitialData);
    const [isCustomerLogged, setIsCustomerLogged] = useState<boolean | null>(null);

    useEffect(() => {
        let isCanceled = false;
        (async () => {
            const response = await fetch(`${path}/graphql`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: userFetch })
            });
            const {data} = await response.json();

            if (isCanceled) return;
            if (data?.viewer?.userId) {
                const user = data?.viewer;
                const {userId:id, email, username, firstName, lastName, registeredDate} = user || {};
                const {
                    uuid,
                    company,
                    position,
                    userAvatar,
                    hasProducts,
                    playtechUser,
                    promotionalEmails,
                    disableAllMarketing,
                    promotionalMessages,
                    browserNotifications
                } = user?.getLoggedUser || {};
                setCurrentUser(
                    {
                        id,
                        uuid,
                        email,
                        company,
                        username,
                        lastName,
                        position,
                        firstName,
                        userAvatar,
                        hasProducts,
                        playtechUser,
                        registeredDate,
                        promotionalEmails,
                        promotionalMessages,
                        disableAllMarketing,
                        browserNotifications
                    }
                );

                setIsCustomerLogged(true);
            } else {
                setIsCustomerLogged(false);
            }
            return () => {
                isCanceled = true;
            };
        })();
    }, [path]);

    return {
        isCustomerLogged,
        currentUser
    };
};

export default useGetUser;
