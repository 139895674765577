export type ValidationRulesType = {
	type: 'required' | 'name' | 'email' | 'confirm' | 'atLeastOneChecked',
	message: string,
	confirmFieldName?: string
}

const isRequired = (value) => {
	if (typeof value === 'string') {
		return !!value && value?.replace(/\s/g, '')?.length !== 0;
	}
	return !!value;
};

const nameValidate = (value) => {
	const re = /^[/\p{L}\s]+$/gu;
	return re.test(value);
};

const atLeastOneCheckedValidate = array => !!array && array?.length > 0;

const emailValidate = (value) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
	const graphQlUnsupported = /^[^'"\\•]*$/g;
	return re.test(value) && graphQlUnsupported.test(value);
};

const confirmValidate = (mainValue, confirmValue) => mainValue === confirmValue;

export default {
	required: isRequired,
	name: nameValidate,
	email: emailValidate,
	confirm: confirmValidate,
	atLeastOneChecked: atLeastOneCheckedValidate
};
