import React, {useCallback, useContext, useState} from 'react';
import Image from 'js/components/Common/Image/Image';
import isEmptyObject from 'js/helpers/isEmptyObject';
import {NavLink, useLocation} from 'react-router-dom';
import Button from 'js/components/Common/Button/Button';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import {ImageBlock} from 'js/components/Common/Image/ImageBlockDataInterface';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './UserProfile.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface Props {
    userData:any,
    logOutCallback: any,
    offsetTop: number
}

type AvatarsType = {
    avatarOne: ImageBlock,
    avatarTwo: ImageBlock,
    avatarThree: ImageBlock,
    avatarFour: ImageBlock
}

const UserProfile = ({userData, logOutCallback, offsetTop}: Props) => {
    const location = useLocation();
    const [avatars, setAvatars] = useState<AvatarsType>(null);
    const {oneLoginBaseURL, activeAvatar} = useContext(CommonContext);

    const { isLoading, error } = useFetchData({
        query: fetchData(),
        condition: true,
        updateData: (data) => {
            const result = data?.themeGeneralSettings?.Options;
            if (!result) {
                return;
            }
            setAvatars(result?.avatars);
        }
    });

    const getNavLink = useCallback((link:string, label:string, external:boolean = false) => {
        if (external) {
            return (
                <li className={classes.UserProfileNavigationItem}>
                    <a
                        target={'_blank'}
                        href={link}
                        rel={'nofollow noopener noreferrer'}>{label}</a>
                </li>
            );
        }
        if (link === location.pathname) {
            return (
                <li className={`${classes.UserProfileNavigationItem} active`}>
                    <span className={`${classes.currentItemLink} emptyLink`}>{label}</span>
                </li>
            );
        }
        return (
            <li className={classes.UserProfileNavigationItem}>
                <NavLink exact={true} to={link}>{label}</NavLink>
            </li>
        );
    }, [location.pathname]);

    const getUserNameAbbreviation = useCallback(() => {
        const {firstName, lastName} = userData || {};
        return isEmptyObject(avatars) ? <Image
            mediaDetails={avatars[activeAvatar]?.mediaDetails}
            mediaItemUrl={avatars[activeAvatar]?.mediaItemUrl}
            size={'thumbnail'}
            alt={avatars[activeAvatar]?.altText} /> : firstName?.substring(0, 1) + lastName?.substring(0, 1);
    }, [activeAvatar, userData, avatars]);
    return (
        <DataValidator data={!isLoading && !error}>
            <div className={classes.UserProfile}>
                <span className={classes.UserProfileAbbreviation}>{getUserNameAbbreviation()}</span>
                <nav style={{marginTop: `${offsetTop}px`}} className={classes.UserProfileMenu}>
                    <ul>
                        {getNavLink('/profile', 'My Profile')}
                        {getNavLink('/contact-us', 'Contact Us')}
                        {getNavLink(
                            `${oneLoginBaseURL}/myaccount`,
                            'OneLogin Account',
                            true
                        )}
                        <li className={classes.UserProfileNavigationItem}>
                            <Button type="button" buttonType={'ButtonUncolored'} text="Log out" handler={() => {
                                logOutCallback();
                                MixPanelWrapper.Reset().then((r) => {
                                    console.log(r);
                                });
                            }} />
                        </li>
                    </ul>
                </nav>
            </div>
        </DataValidator>
    );
};

export default UserProfile;
