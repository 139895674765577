import React, {useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import Label from 'js/components/Search/Label/Label';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Thumbnail from 'js/components/Search/Thumbnail/Thumbnail';
import Description from 'js/components/Common/Description/Description';
import PostDuration from 'js/components/Common/PostDuration/PostDuration';
import VisibilityViewerIcon from 'js/components/Common/VisibilityViewerIcon/VisibilityViewerIcon';

import commonClasses from '../../../SearchResultsItem/SearchResultsItem.module.pcss';


export interface Props {
    data: {
        id: number,
        title: string,
        link: string,
        featuredImage: {
            url: string,
            altText: string
        },
        type: 'courses',
        duration: number,
        description: string,
        isPremium: boolean
    },
    isPromoted: boolean
}

const Course = ({data, isPromoted}:Props) => {
    const {isPremium} = data || {};
    const location = useLocation();

    const sendMixPanelEvent = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            'Start Course',
            {
                category: 'Search Results',
                value: data?.title
            });
    }, [data]);

    return (
        <div className={`${commonClasses.SearchResultItem} ${isPromoted && commonClasses.Promoted}`}>
            <Thumbnail thumbnail={data?.featuredImage} />
            <div className={commonClasses.SearchResultItemInfo}>
                <Heading text={`${data?.title}`} type={'h5'} />
                <Description data={data?.description}/>
                <div className={commonClasses.Wrap}>
                    <Label type={'courses'} />
                    <PostDuration showIcon={false} duration={data?.duration}/>
                </div>
                <div className={commonClasses.Wrap}>
                    <Button
                        buttonType={'ButtonUncolored'}
                        target={'_blank'}
                        link={`/courses/${data?.link}?source=${location?.pathname}&sourceName=search`}
                        type={'a'}
                        text={isPremium ? 'Buy Now' : 'Start Course'}
                        handler={sendMixPanelEvent} />
                    <VisibilityViewerIcon id={data?.id} />
                </div>
            </div>
        </div>
    );
};

export default Course;
