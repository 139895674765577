import React, {useState} from 'react';

export type FilterTaxDataType = {
	suitableFor: string,
	skills: string,
	productLegacy: string,
	language: string,
	articleType: string,
	courseType: string,
	eventType: string,
	videoType: string,
	podcastType: string,
	presentationType: string,
	manualType: string
};

type CountType = {
	name: string,
	count: number
};

export const SearchContext = React.createContext({
	filterEntitiesData: '',
	setFilterEntitiesData: value => value,

	filterTaxData: {
		suitableFor: '',
		skills: '',
		productLegacy: '',
		language: '',
		articleType: '',
		courseType: '',
		eventType: '',
		videoType: '',
		podcastType: '',
		presentationType: '',
		manualType: ''
	},

	setFilterTaxData: value => value,
	entitiesCounts: [],
	setEntitiesCounts: value => value,
	filterRatingData: '',
	setFilterRatingData: value => value,
	orderBy: '',
	setOrderBy: value => value,
	ratingCounts: [],
	setRatingCounts: value => value,
	searchStatus: false,
	setSearchStatus: value => value,
	taxCounts: {
		suitableFor: [],
		skills: [],
		productLegacy: [],
		language: [],
		articleType: [],
		courseType: [],
		eventType: [],
		videoType: [],
		podcastType: [],
		presentationType: [],
		manualType: []
	},
	setTaxCounts: value => value
});

const SearchContextProvider = (props) => {
	const [orderBy, setOrderBy] = useState<
		'mostRelevant' | 'highestRated' | 'type' | 'newest'
		>('mostRelevant');
	const [entitiesCounts, setEntitiesCounts] = useState<[]>([]);
	const [searchStatus, setSearchStatus] = useState<boolean>(false);
	const [ratingCounts, setRatingCounts] = useState<[]>([]);

	const [taxCounts, setTaxCounts] = useState<
		{
			suitableFor: Array<CountType>,
			skills: Array<CountType>,
			productLegacy: Array<CountType>,
			language: Array<CountType>,
			articleType: Array<CountType>,
			courseType: Array<CountType>,
			eventType: Array<CountType>,
			videoType: Array<CountType>,
			podcastType: Array<CountType>,
			presentationType: Array<CountType>,
			manualType: Array<CountType>
		}
	>({
		suitableFor: [],
		skills: [],
		productLegacy: [],
		language: [],
		articleType: [],
		courseType: [],
		eventType: [],
		videoType: [],
		podcastType: [],
		presentationType: [],
		manualType: []
	});

	const [filterEntitiesData, setFilterEntitiesData] = useState<'articles' | 'courses' | 'manuals' | 'meetings' | 'podcasts' | 'videos' | 'presentations' | ''>
	('');

	const [filterTaxData, setFilterTaxData] = useState<FilterTaxDataType>({
		suitableFor: '',
		skills: '',
		productLegacy: '',
		language: '',
		articleType: '',
		courseType: '',
		eventType: '',
		videoType: '',
		podcastType: '',
		presentationType: '',
		manualType: ''
	});

	const [filterRatingData, setFilterRatingData] = useState('');

	return (
		<SearchContext.Provider value={{
			orderBy,
			setOrderBy,
			searchStatus,
			ratingCounts,
			entitiesCounts,
			setRatingCounts,
			setSearchStatus,
			filterRatingData,
			setEntitiesCounts,
			filterEntitiesData,
			setFilterRatingData,
			setFilterEntitiesData,
			filterTaxData,
			setFilterTaxData,
			setTaxCounts,
			taxCounts
		}}>
			{props.children}
		</SearchContext.Provider>
	);
};

export default SearchContextProvider;
