import React, {useCallback, useContext, useState, useEffect, useRef} from 'react';
import parse from 'html-react-parser';
import {SearchContext} from 'js/context/SearchContext';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Input from 'js/components/Common/FormComponents/Input/Input';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import useSearchSetViaHistory from 'js/hooks/useSearchSetViaHistory/useSearchSetViaHistory';

import fetchData from './fetchData';
import classes from './FilterItemSection.module.pcss';
import useAnchor from 'js/hooks/useAnchor/useAnchor';

export type TaxonomyType =
    'suitableFor' |
    'skills' |
    'productLegacy' |
    'language' |
    'articleType' |
    'courseType' |
    'eventType' |
    'videoType' |
    'podcastType' |
    'presentationType' |
    'manualType';

export interface Props {
    heading: string,
    type: 'entities' | 'taxonomy' | 'rating',
    taxonomyName?: TaxonomyType
}

type FilterItemType = {
    id: number,
    label: string | JSX.Element | JSX.Element[],
    value: string,
    name?: string,
    count?: number,
    disabled?: any,
    isChecked?: boolean
}

const FilterItemSection = ({heading, type, taxonomyName}:Props) => {
    const nodeRef = useRef(null);
    const anchor = useAnchor('Search', 0, -150);
    const controllerRef = useRef<AbortController | null>();
    const [isCountsLoaded, setIsCountsLoaded] = useState<boolean>(false);
    const [itemSectionData, setItemSectionData] = useState<Array<FilterItemType>>([]);
    const [itemRawSectionData, setRawItemSectionData] = useState<Array<{
        termTaxonomyId: number,
        name: string,
        slug: string
    }>>([]);
    const {apiPath, searchQuery, setSearchPage} = useContext(CommonContext);
    const {
        taxCounts,
        ratingCounts,
        searchStatus,
        filterTaxData,
        entitiesCounts,
        setSearchStatus,
        setFilterTaxData,
        setFilterRatingData,
        setFilterEntitiesData
    } = useContext(SearchContext);

    const [ratingCountsCopy, setRatingCountsCopy] = useState([]);
    const [entitiesCountsCopy, setEntitiesCountsCopy] = useState([]);

    useSearchSetViaHistory(nodeRef, itemSectionData, type, taxonomyName);

    useEffect(() => {
        switch (type) {
            case 'entities': setEntitiesCountsCopy(entitiesCounts); break;
            case 'rating': setRatingCountsCopy(ratingCounts); break;
            default : break;
        }
    }, [
        type,
        ratingCounts,
        entitiesCounts
    ]);

    const showItemsCount = useCallback((count) => {
        if (!count) {
            return '';
        }
        return `(${new Intl.NumberFormat('en-IN').format(count)})`;
    }, []);

    const makeCheckboxBehaveLikeRadio = useCallback((name) => {
        const {current} = nodeRef || {};
        const inputList:NodeListOf<any> = current?.querySelectorAll('input');
        inputList?.forEach((item) => {
            if (item.name !== name) {
                item.checked = false;
            }
        });
    }, []);

    const setFilterTaxDataByType = useCallback((targetName, isChecked) => {
        if (isChecked) {
            if (type === 'entities') {
                return {
                    suitableFor: '',
                    skills: '',
                    productLegacy: '',
                    language: '',
                    articleType: '',
                    courseType: '',
                    eventType: '',
                    videoType: '',
                    podcastType: '',
                    presentationType: '',
                    manualType: ''
                };
            }
            return false;
        }
        setFilterRatingData('');
        let filterTaxDataClone = {...filterTaxData};
        switch (type) {
            case 'entities': {
                filterTaxDataClone = {
                    suitableFor: '',
                    skills: '',
                    productLegacy: '',
                    language: '',
                    articleType: '',
                    courseType: '',
                    eventType: '',
                    videoType: '',
                    podcastType: '',
                    presentationType: '',
                    manualType: ''
                };
                break;
            }
            case 'taxonomy': {
                switch (taxonomyName) {
                    case 'suitableFor': {
                        filterTaxDataClone.language = '';
                        filterTaxDataClone.suitableFor = '';
                        break;
                    }
                    case 'skills': {
                        filterTaxDataClone.language = '';
                        filterTaxDataClone.suitableFor = '';
                        filterTaxDataClone.skills = '';
                        break;
                    }
                    case 'productLegacy': {
                        filterTaxDataClone.language = '';
                        filterTaxDataClone.suitableFor = '';
                        filterTaxDataClone.skills = '';
                        filterTaxDataClone.productLegacy = '';
                        break;
                    }
                    case 'articleType':
                    case 'eventType':
                    case 'manualType':
                    case 'videoType':
                    case 'podcastType':
                    case 'courseType':
                    case 'presentationType':
                    {
                        filterTaxDataClone = {
                            suitableFor: '',
                            skills: '',
                            productLegacy: '',
                            language: '',
                            articleType: '',
                            courseType: '',
                            eventType: '',
                            videoType: '',
                            podcastType: '',
                            presentationType: '',
                            manualType: ''
                        };
                        break;
                    }
                    default : return false;
                }
                break;
            }
            default : return false;
        }

        return filterTaxDataClone;

    }, [
        type,
        taxonomyName,
        filterTaxData,
        setFilterRatingData
    ]);

    const filterCheckHandler = useCallback(async (e) => {
        const {checked} = e?.target;
        const targetName = e?.target?.name;

        if (searchQuery) {
            setSearchStatus(false);
            setSearchPage(1);
        }
        makeCheckboxBehaveLikeRadio(targetName);
        const filterTaxDataByType = setFilterTaxDataByType(targetName, checked);

        if (checked) {
            let val = targetName;

            if (type === 'rating') {
                const elementNode = document.getElementById(e.currentTarget.id);
                const elementWithRating = elementNode.nextElementSibling.querySelector('[data-rating]');
                val = elementWithRating.getAttribute('data-rating');
            }

            await MixPanelWrapper.TrackEvent(
                'Search Filtering',
                {
                    category: 'Search Filters',
                    type: heading,
                    value: val
                }
            );
        }

        anchor();

        switch (type) {
            case 'entities': {
                if (filterTaxDataByType) {
                    setFilterTaxData(filterTaxDataByType);
                }
                setFilterRatingData('');
                return setFilterEntitiesData(checked ? targetName : '');
            }
            case 'taxonomy': {
                let filterTaxDataCopy = {};
                if (filterTaxDataByType) {
                    setFilterRatingData('');
                    return setFilterTaxData(filterTaxDataByType);
                } else {
                    filterTaxDataCopy = {...filterTaxData};
                    filterTaxDataCopy[taxonomyName] =  checked ? targetName : '';
                    return setFilterTaxData(filterTaxDataCopy);
                }
            }
            case 'rating': {
                return setFilterRatingData(checked ? targetName : '');
            }
            default : return;
        }
    }, [
        type,
        anchor,
        heading,
        searchQuery,
        taxonomyName,
        filterTaxData,
        setSearchPage,
        setSearchStatus,
        setFilterTaxData,
        setFilterRatingData,
        setFilterEntitiesData,
        setFilterTaxDataByType,
        makeCheckboxBehaveLikeRadio
    ]);

    const getTaxItemCount = useCallback((item) => {
        const key = `term_id_${item?.termTaxonomyId}`;
        const itemObj = taxCounts[taxonomyName]?.find(subItem => subItem?.name === key);
        return itemObj?.count || 0;
    }, [taxCounts, taxonomyName]);

    const setTaxCounts = useCallback(data => data?.filter(item => !!getTaxItemCount(item))?.map((item) => {
        const itemCount = getTaxItemCount(item);
        return (
            {
                id: item?.termTaxonomyId,
                label: parse(`${item?.name}&nbsp;${showItemsCount(itemCount)}`),
                value: item?.slug,
                name: item?.name,
                count: itemCount
            }
        );
    }), [showItemsCount, getTaxItemCount]);

    const getTaxResponse = useCallback((ref) => {
        setIsCountsLoaded(false);
        let taxonomyNameQuery:string = taxonomyName;
        if (taxonomyName === 'manualType') {
            taxonomyNameQuery = 'manualsType';
        }
        (async () => {
            try {
                const response = await fetch(`${apiPath}/graphql`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    signal: ref?.signal,
                    body: JSON.stringify({
                        query: `${fetchData(`${taxonomyNameQuery}List`)}`
                    })
                });
                const { data } = await response.json();
                const result = data[`${taxonomyNameQuery}List`]?.nodes;

                if (result?.length === 0) {
                    setRawItemSectionData([]);
                    return;
                }
                setRawItemSectionData(setTaxCounts(result));
            } catch (e) {

            }
        })();
    }, [
        apiPath,
        setTaxCounts,
        taxonomyName
    ]);

    const quickSort = useCallback((arr) => {
        if (arr.length < 2) return arr;
        const pivot = arr[0];
        const left = [];
        const right = [];

        for (let i = 1; i < arr.length; i++) {
            if (pivot?.count < arr[i]?.count) {
                left.push(arr[i]);
            } else {
                right.push(arr[i]);
            }
        }
        return quickSort(left).concat(pivot, quickSort(right));
    }, []);

    useEffect(() => {
        const arr = quickSort([...itemRawSectionData]);
        setItemSectionData(arr);
        setIsCountsLoaded(true);
    }, [quickSort, itemRawSectionData]);

    useEffect(() => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        if (type === 'taxonomy') {
            return;
        }
        switch (type)  {
            case 'entities': {
                const counts = [];
                entitiesCountsCopy?.map((item) => {
                    counts[item['name']] = item['count'];
                });
                const data:Array<FilterItemType> = [
                    {
                        id: 1,
                        label: parse(`Articles&nbsp;<span datatype="count">${showItemsCount(counts['articles'])}</span>`),
                        value: 'articles',
                        disabled: counts['articles']
                    },
                    {
                        id: 2,
                        label: parse(`Courses&nbsp;<span datatype="count">${showItemsCount(counts['courses'])}</span>`),
                        value: 'courses',
                        disabled: counts['courses']
                    },
                    {
                        id: 3,
                        label: parse(`Live Events&nbsp;<span datatype="count">${showItemsCount(counts['meetings'])}</span>`),
                        value: 'meetings',
                        disabled: counts['meetings']
                    },
                    {
                        id: 4,
                        label: parse(`Manuals&nbsp;<span datatype="count">${showItemsCount(counts['manuals'])}</span>`),
                        value: 'manuals',
                        disabled: counts['manuals']
                    },
                    {
                        id: 5,
                        label: parse(`Videos&nbsp;<span datatype="count">${showItemsCount(counts['videos'])}</span>`),
                        value: 'videos',
                        disabled: counts['videos']
                    },
                    // {
                    //     id: 6,
                    //     label: parse(`Podcasts&nbsp;<span datatype="count">${showItemsCount(counts['podcasts'])}</span>`),
                    //     value: 'podcasts',
                    //     disabled: counts['podcasts']
                    // },
                    {
                        id: 7,
                        label: parse(`Presentations&nbsp;<span datatype="count">${showItemsCount(counts['presentations'])}</span>`),
                        value: 'presentations',
                        disabled: counts['presentations']
                    }
                ];
                setItemSectionData(data);
                break;
            }
            case 'rating': {
                const counts = [];
                ratingCountsCopy?.map((item) => {
                    counts[item['name']] = item['count'];
                });
                const data:Array<FilterItemType> = [];
                if (counts['5']) {
                    data.unshift(
                        {
                            id: 1,
                            label: parse(
                                `<div data-rating="5"></div><span>${showItemsCount(counts['5'])}</span>`
                            ),
                            value: '5'
                        }
                    );
                }

                if (counts['4']) {
                    data.unshift(
                        {
                            id: 2,
                            label: parse(
                                `<div data-rating="4"></div><span>${showItemsCount(counts['4'])}</span>`
                            ),
                            value: '4'
                        }
                    );
                }

                if (counts['3']) {
                    data.unshift(
                        {
                            id: 3,
                            label: parse(
                                `<div data-rating="3"></div><span>${showItemsCount(counts['3'])}</span>`
                            ),
                            value: '3'
                        }
                    );
                }
                setItemSectionData(data);
                break;
            }
            default : break;
        }
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort();
                controllerRef.current = null;
            }
        };
    }, [
        type,
        showItemsCount,
        ratingCountsCopy,
        entitiesCountsCopy
    ]);

    useEffect(() => {
        if (type !== 'taxonomy') {
            return;
        }
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        getTaxResponse(controllerRef?.current);
        return () => {
            if (controllerRef.current) {
                controllerRef.current.abort();
                controllerRef.current = null;
            }
        };
    }, [
        type,
        getTaxResponse
    ]);

    return (
        itemSectionData?.length > 0 ?
            <section
		        className={`${classes.FilterSection} ${(searchStatus || searchQuery.length === 0 ) && isCountsLoaded ? classes.FilterSectionActive : ''}`}
		        datatype={type}
		        data-tax={taxonomyName}
		        ref={nodeRef}>
                <Heading text={heading} type={'h6'}/>
                <div className={classes.FilterSectionItems}>
                  {itemSectionData?.map(item =>
                      <div
                          key={item?.id}
                          datatype={item?.value}
                          className={classes.FilterSectionItem}>
                          <Input
                              type="checkbox"
                              withLabel={true}
                              name={item?.name || ''}
                              slug={type === 'taxonomy' ? item?.name : item?.value || ''}
                              labelText={item?.label || ''}
                              id={item?.id?.toString()}
                              onChange={e => filterCheckHandler(e)}
                          />
                      </div>
                  )}
                </div>
            </section> : null
    );
};

export default FilterItemSection;
