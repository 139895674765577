import {useCallback, useContext} from 'react';
import {SearchContext} from 'js/context/SearchContext';

const useSearchReset = ({nodeRef, isLocalRef = true}) => {
    const {
        setFilterEntitiesData,
        setFilterRatingData,
        setSearchStatus,
        setFilterTaxData
    } = useContext(SearchContext);
    return useCallback(() => {
        let filterParent = null;
        if (isLocalRef && nodeRef?.current) {
            filterParent = nodeRef?.current;
        } else if (!isLocalRef) {
            filterParent = nodeRef?.current?.parentNode;
        } else {
            return;
        }
        const allItems = filterParent?.querySelectorAll('input');
        setFilterEntitiesData('');
        setFilterTaxData({
            suitableFor: '',
            skills: '',
            productLegacy: '',
            language: '',
            articleType: '',
            courseType: '',
            eventType: '',
            videoType: '',
            podcastType: '',
            presentationType: '',
            manualType: ''
        });
        setFilterRatingData('');
        setSearchStatus(false);

        allItems.forEach((item) => {
            item.disabled = false;
            item.checked = false;
        });
    }, [
        nodeRef,
        isLocalRef,
        setSearchStatus,
        setFilterTaxData,
        setFilterEntitiesData,
        setFilterRatingData
    ]);
};

export default useSearchReset;
