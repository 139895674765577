import React from 'react';
import parse from 'html-react-parser';
import cookieImg from 'images/cookie.svg';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';

import classes from '../CookiePolicyPopup.module.pcss';

interface Props {
    title: string,
    info: string,
    setContentStage: Function,
    setAllCookiesHandler: Function
}
const FirstStage = ({title, info, setAllCookiesHandler, setContentStage}:Props) => (
    <div className={`${classes.Content} ${classes.FirstStage}`}>
        <div className={classes.Heading}>
            <Image mediaItemUrl={cookieImg} alt={'Cookie Policy'}/>
            <Heading text={title} type={'h5'}/>
        </div>
        <div className={classes.Info}>
            {parse(info || '')}
        </div>
        <div className={classes.ButtonsWrap}>
            <Button
                type={'button'}
                buttonColorType={'Pink'}
                buttonType={'ButtonColored'}
                handler={() => {
                    setAllCookiesHandler(true);
                }}
                text={'Accept all cookies'}/>
            <Button
                type={'button'}
                buttonColorType={'Pink'}
                buttonType={'ButtonUncolored'}
                handler={() => setContentStage(2)}
                text={'Manage my cookie preferences'}/>
            <Button
                type={'button'}
                buttonColorType={'Violet'}
                buttonType={'ButtonUncolored'}
                handler={() => {
                    setAllCookiesHandler(false);
                }}
                text={'Decline all optional cookies'}/>
        </div>
    </div>
);

export default FirstStage;
