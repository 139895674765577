import React, {useMemo} from 'react';
import classes from 'js/components/Search/Label/Label.module.pcss';
import {EntitiesTypesDataInterface} from 'js/containers/Pages/Search/SearchResults/Entities/EntitiesTypesDataInterface';

export interface Props {
    type: EntitiesTypesDataInterface,
    additionalStyles?: any
}

const Label = ({type, additionalStyles = {} }: Props) => {
    const labelByType = useMemo(() => {
        switch (type) {
            case 'articles' : return (
                <>
                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9375 0C13.2422 0 13.5 0.257812 13.5 0.5625V8.4375C13.5 8.76562 13.2422 9 12.9375 9H1.3125C0.585938 9 0 8.41406 0 7.6875V1.3125C0 1.00781 0.234375 0.75 0.5625 0.75H1.54688C1.71094 0.328125 2.13281 0 2.625 0H12.9375ZM1.125 7.6875C1.125 7.80469 1.19531 7.875 1.3125 7.875C1.40625 7.875 1.5 7.80469 1.5 7.6875V1.875H1.125V7.6875ZM12.375 7.875V1.125H2.625V7.6875C2.625 7.75781 2.60156 7.82812 2.60156 7.875H12.375ZM4.03125 5.0625C3.86719 5.0625 3.75 4.94531 3.75 4.78125V2.53125C3.75 2.39062 3.86719 2.25 4.03125 2.25H7.21875C7.35938 2.25 7.5 2.39062 7.5 2.53125V4.78125C7.5 4.94531 7.35938 5.0625 7.21875 5.0625H4.03125ZM4.6875 3.1875V4.125H6.5625V3.1875H4.6875ZM3.75 6.46875V5.90625C3.75 5.76562 3.86719 5.625 4.03125 5.625H7.21875C7.35938 5.625 7.5 5.76562 7.5 5.90625V6.46875C7.5 6.63281 7.35938 6.75 7.21875 6.75H4.03125C3.86719 6.75 3.75 6.63281 3.75 6.46875ZM8.25 6.46875V5.90625C8.25 5.76562 8.36719 5.625 8.53125 5.625H10.9688C11.1094 5.625 11.25 5.76562 11.25 5.90625V6.46875C11.25 6.63281 11.1094 6.75 10.9688 6.75H8.53125C8.36719 6.75 8.25 6.63281 8.25 6.46875ZM8.25 3.09375V2.53125C8.25 2.39062 8.36719 2.25 8.53125 2.25H10.9688C11.1094 2.25 11.25 2.39062 11.25 2.53125V3.09375C11.25 3.25781 11.1094 3.375 10.9688 3.375H8.53125C8.36719 3.375 8.25 3.25781 8.25 3.09375ZM8.25 4.78125V4.21875C8.25 4.07812 8.36719 3.9375 8.53125 3.9375H10.9688C11.1094 3.9375 11.25 4.07812 11.25 4.21875V4.78125C11.25 4.94531 11.1094 5.0625 10.9688 5.0625H8.53125C8.36719 5.0625 8.25 4.94531 8.25 4.78125Z" fill="#FF3984"/>
                    </svg>
                    <span>Article</span>
                </>
            );
            case 'meetings' : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                        <path d="M7.9375 4.25C8.35938 4.25 8.64062 4.74219 8.40625 5.09375L4.28125 12.2188C4.1875 12.4062 4 12.5 3.78906 12.5C3.4375 12.5 3.17969 12.1719 3.25 11.8203L4.32812 7.25H1.5625C1.21094 7.25 0.953125 6.96875 1 6.61719L1.75 0.992188C1.77344 0.710938 2.03125 0.5 2.3125 0.5H5.6875C6.03906 0.5 6.32031 0.851562 6.22656 1.22656L5.21875 4.25H7.9375Z" fill="#FF3984"/>
                    </svg>
                    <span>Live Event</span>
                </>
            );
            case 'courses' : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                        <path d="M15.5781 3.10938C16.1172 3.27344 16.1172 4 15.5781 4.16406L9.03906 6.17969C8.57031 6.32031 8.14844 6.25 7.9375 6.17969L3.36719 4.77344C3.0625 4.96094 2.89844 5.28906 2.875 5.64062C3.08594 5.75781 3.25 5.99219 3.25 6.25C3.25 6.50781 3.10938 6.71875 2.92188 6.85938L3.50781 9.55469C3.55469 9.78906 3.39062 10 3.15625 10H1.82031C1.58594 10 1.42188 9.78906 1.46875 9.55469L2.05469 6.85938C1.86719 6.71875 1.75 6.50781 1.75 6.25C1.75 5.99219 1.89062 5.75781 2.125 5.61719C2.14844 5.21875 2.28906 4.82031 2.54688 4.51562L1.39844 4.16406C0.859375 4 0.859375 3.27344 1.39844 3.10938L7.9375 1.09375C8.28906 0.976562 8.6875 0.976562 9.03906 1.09375L15.5781 3.10938ZM9.25 6.90625L12.6484 5.85156L13 8.5C13 9.34375 10.9844 10 8.5 10C5.99219 10 4 9.34375 4 8.5L4.32812 5.85156L7.72656 6.90625C8.00781 7 8.59375 7.09375 9.25 6.90625Z" fill="#FF3984"/>
                    </svg>
                    <span>Online Course</span>
                </>
            );
            case 'manuals' : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                        <path d="M13.4766 11.1641C13.5234 11.375 13.4062 11.5625 13.2188 11.6328L12.8438 11.7266C12.6797 11.7734 12.4922 11.6797 12.4219 11.5156C12.0938 11.5156 10.8516 11.8438 10.5938 12.0078C10.5938 12.1953 10.5 12.3594 10.3125 12.4062L9.96094 12.5C9.75 12.5469 9.53906 12.4297 9.49219 12.2422L7.125 3.21875V11.75C7.125 12.1719 6.77344 12.5 6.1875 12.5H0.5625C0.328125 12.5 0 12.1719 0 11.75V1.25C0 0.851562 0.328125 0.5 0.5625 0.5H6.1875C6.77344 0.5 7.125 0.851562 7.125 1.25V1.36719L7.38281 1.29688C7.54688 1.25 7.73438 1.34375 7.80469 1.50781C8.13281 1.50781 9.375 1.17969 9.63281 1.01562C9.63281 0.828125 9.72656 0.664062 9.91406 0.617188L10.2656 0.523438C10.4766 0.476562 10.6875 0.59375 10.7344 0.78125L13.4766 11.1641ZM3 11.375V10.25H1.125V11.375H3ZM3 9.125V3.875H1.125V9.125H3ZM3 2.75V1.625H1.125V2.75H3ZM6 11.375V10.25H4.125V11.375H6ZM6 9.125V3.875H4.125V9.125H6ZM6 2.75V1.625H4.125V2.75H6ZM10.3359 11.0938C10.7344 10.9062 11.7656 10.6484 12.1641 10.6016L9.89062 1.92969C9.49219 2.11719 8.46094 2.375 8.0625 2.42188L10.3359 11.0938Z" fill="#FF3984"/>
                    </svg>
                    <span>Manual</span>
                </>
            );
            case 'videos'  : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                        <path d="M7.875 0C8.48438 0 9 0.515625 9 1.125V7.89844C9 8.50781 8.48438 9 7.875 9H1.10156C0.492188 9 0 8.50781 0 7.89844V1.125C0 0.515625 0.492188 0 1.10156 0H7.875ZM12.3047 0.890625C12.7969 0.5625 13.5 0.914062 13.5 1.5V7.52344C13.5 8.10938 12.7969 8.46094 12.3047 8.13281L9.75 6.35156V2.67188L12.3047 0.890625Z" fill="#FF3984"/>
                    </svg>
                    <span>Video</span>
                </>
            );
            case 'podcasts' : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                        <path d="M4.125 8.75C2.85938 8.75 1.875 7.76562 1.875 6.5V2.75C1.875 1.50781 2.85938 0.5 4.125 0.5C5.36719 0.5 6.375 1.50781 6.375 2.75V6.5C6.375 7.76562 5.36719 8.75 4.125 8.75ZM7.875 5C8.0625 5 8.25 5.1875 8.25 5.375V6.5C8.25 8.58594 6.67969 10.3203 4.6875 10.6016V11.375H6C6.1875 11.375 6.375 11.5625 6.375 11.75V12.125C6.375 12.3359 6.1875 12.5 6 12.5H2.25C2.03906 12.5 1.875 12.3359 1.875 12.125V11.75C1.875 11.5625 2.03906 11.375 2.25 11.375H3.5625V10.5781C1.47656 10.2969 0 8.42188 0 6.33594V5.375C0 5.1875 0.164062 5 0.375 5H0.75C0.9375 5 1.125 5.1875 1.125 5.375V6.38281C1.125 7.95312 2.25 9.33594 3.82031 9.5C5.60156 9.66406 7.125 8.25781 7.125 6.5V5.375C7.125 5.1875 7.28906 5 7.5 5H7.875Z" fill="#FF3984"/>
                    </svg>
                    <span>Podcast</span>
                </>
            );
            case 'presentations' : return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                        <path d="M13.125 0.5C13.3125 0.5 13.5 0.6875 13.5 0.875V1.25C13.5 1.46094 13.3125 1.625 13.125 1.625H12.75V8C12.75 8.42188 12.3984 8.75 12 8.75H7.3125V9.78125L9.11719 11.6094C9.25781 11.75 9.25781 11.9844 9.11719 12.125L8.85938 12.4062C8.71875 12.5469 8.46094 12.5469 8.32031 12.4062L6.75 10.8125L5.15625 12.4062C5.01562 12.5469 4.75781 12.5469 4.61719 12.4062L4.35938 12.125C4.21875 11.9844 4.21875 11.75 4.35938 11.6094L6.1875 9.78125V8.75H1.5C1.07812 8.75 0.75 8.42188 0.75 8V1.625H0.375C0.164062 1.625 0 1.46094 0 1.25V0.875C0 0.6875 0.164062 0.5 0.375 0.5H13.125ZM11.625 7.625V1.625H1.875V7.625H11.625Z" fill="#FF3984"/>
                    </svg>
                    <span>Presentation</span>
                </>
            );
            default : return null;
        }
    }, [type]);
    return (
        <span className={classes.Label} style={additionalStyles}>
            {labelByType}
        </span>
    );
};

export default Label;
