import React, {Ref, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import parse from 'html-react-parser';
import Highlighter from 'react-highlight-words';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import classes from './AutoComplete.module.pcss';
import {SearchContext} from 'js/context/SearchContext';

export interface Props {
    query: string,
    focus: number | null,
    itemClick: any,
    itemChange: any,
    show: boolean,
    itemRef: Ref<any>
}

export interface AutoCompleteTitles {
    title: string,
    id: number,
    search_count: number
}

const AutoComplete = ({query, focus, itemClick, itemChange, show, itemRef}: Props) => {
    const ulRef = useRef(null);
    const {setAtoCompleteSpinnerStatus, setShowSearch} = useContext(CommonContext);
    const {setSearchStatus} = useContext(SearchContext);
    const [titles, setTitles] = useState<AutoCompleteTitles[]>([]);
    const sep = useMemo(() => /\W+/g, []);

    const calculateWords = useCallback((str) => {
        let result = str.replace(/(^\s*)|(\s*$)/gi, '');
        result = result.replace(/[ ]{2,}/gi, ' ');
        result = result.replace(/\n /, '\n');
        return result.split(' ').length;
    }, []);

    const sendMixPanelEvent = useCallback(async (value: string) => {
        await MixPanelWrapper.TrackEvent(
            'Auto complete is used',
            {
                value,
                category: 'Search Input',
                words: calculateWords(value),
                characters: value?.length
            });
    }, [calculateWords]);

    const itemClickHandler = useCallback(async (e:any, id: number) => {
        itemClick(e, id);
        setTitles([]);
        setShowSearch(false);
        setSearchStatus(false);
        await sendMixPanelEvent(e?.currentTarget?.textContent);
    }, [sendMixPanelEvent, itemClick, setSearchStatus, setShowSearch]);

    const findChunksAtBeginningOfWords = useCallback((textToHighlight, searchWords) => {
        const chunks = [];
        const textLow = textToHighlight.toLowerCase();
        const singleTextWords = textLow.split(sep);
        let fromIndex = 0;

        const singleTextWordsWithPos = singleTextWords?.map((s) => {
            const indexInWord = textLow?.indexOf(s, fromIndex);
            fromIndex = s?.length + indexInWord;
            return {
                word: s,
                index: indexInWord
            };
        });

        searchWords.forEach((sw) => {
            const swLow = sw.toLowerCase();
            singleTextWordsWithPos.forEach((s) => {
                if (s.word.startsWith(swLow)) {
                    const start = s.index;
                    const end = s.index + swLow.length;
                    chunks.push({
                        start,
                        end
                    });
                }
            });

            if (textLow.startsWith(swLow)) {
                const start = 0;
                const end = swLow.length;
                chunks.push({
                    start,
                    end
                });
            }
        });
        return chunks;
    }, [sep]);

    useEffect(() => {
        const ul = ulRef?.current;
        if (ul) {
            const el = ul?.querySelector('.active');
            const id = el?.getAttribute('data-key');
            itemChange(el, id);
        }
    }, [focus, itemChange]);

    const queryStr = useMemo(() => query, [query]);

    useEffect(() => {
        setTitles([]);
    }, [query]);

    useFetchData({
        query: fetchData(queryStr),
        condition: queryStr?.length > 0,
        updateData: (data, isData) => {
            if (isData) {
                return;
            }
            setTitles(data?.search?.autoCompleteResult);
            setAtoCompleteSpinnerStatus(false);
        }
    });

    const autoCompleteShowVar = useMemo(() =>
        show && titles?.length > 0 && query?.length > 0,
        [titles, query, show]);

    return <DataValidator data={autoCompleteShowVar}>
        <div ref={itemRef} className={classes.AutoComplete}>
            <Heading text={'Suggested Searches'} type={'h4'} />
            <ul data-active-index={focus}
                ref={ulRef}>
                {titles?.map((item, index) => {
                    const {title} = item || {};
                    const rawTitle = parse(title);
                    return (
                        <li className={index === focus ? 'active' : ''}
                            data-key={item?.id}
                            key={item?.id}
                            onClick={e => itemClickHandler(e, item?.id)}
                        >
                            <Highlighter
                                highlightClassName={classes.Selected}
                                searchWords={[query]}
                                autoEscape={true}
                                caseSensitive={false}
                                className="HighlighterItem"
                                unhighlightClassName='Text'
                                textToHighlight={rawTitle}
                                findChunks={() => findChunksAtBeginningOfWords(rawTitle, query.split(sep))}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    </DataValidator>;
};

export default AutoComplete;
