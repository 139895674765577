import React, {useContext, useEffect, useMemo, useState} from 'react';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {HelpPageContext} from 'js/context/HelpPageContext/HelpPageContext';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import ExpandableSectionInfo from 'js/components/Common/ExpandableSection/ExpandableSectionInfo';
import ExpandableSectionHeading from 'js/components/Common/ExpandableSection/ExpandableSectionHeading';

import fetchData from './fetchData';
import classes from './HelpResults.module.pcss';

type CasesType = {
    cases: {
        caseBody: string,
        caseHeader: string
    }[]
}

const HelpResults = ({pageId, nodeRef}: {pageId: number, nodeRef: any}) => {
    const [cases, setCases] = useState<Array<CasesType>>(null);
    const {
        setLoading,
        activeItemId,
        setTotalCount
    } = useContext(HelpPageContext);

    const { isLoading, error } = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data) => {
            const casesList = data?.page?.HelpTemplate?.helpArea;
            setCases(casesList);
            setLoading(false);
        }
    });

    useEffect(() => {
        if (!cases) {
            return;
        }
        setTotalCount(cases[activeItemId]?.cases?.length);
    }, [activeItemId, cases, setTotalCount]);

    const showCases = useMemo(() => {
        if (!cases || activeItemId === null) {
            return null;
        }
        return cases[activeItemId]?.cases?.map((item, index) => (
            <div key={`${activeItemId}_${index}`} className={classes.Item}>
                <ExpandableSectionHeading
                    withWrap={true}
                    isOpened={index === 0}
                    text={item?.caseHeader}
                    nodeRef={nodeRef} />
                <ExpandableSectionInfo
                    isOpened={index === 0}
                    data={item?.caseBody} />
            </div>
        ));
    }, [activeItemId, cases, nodeRef]);

    return (
        <DataValidator data={!isLoading && !error}>
            <article className={classes.HelpResults}>
                {showCases}
            </article>
        </DataValidator>
    );
};

export default HelpResults;
