const userFetch = (
	`
	{
		viewer {
		  email
		  userId
		  lastName
		  username
		  firstName
		  registeredDate
		  getLoggedUser {
		  	uuid
			company
			position
			userAvatar
			hasProducts
			promotionalEmails
			playtechUser
		  	promotionalMessages
		  	disableAllMarketing
		  	browserNotifications
		  }
		}
	}

	`
);

export default userFetch;

