import React, {useEffect, useState, useContext, useCallback, useMemo, useRef} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import Logo from 'js/components/Common/Logo/Logo';
import Container from 'js/components/Common/Container/Container';
import Notifications from 'js/components/Notifications/Notifications';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import SearchForm from 'js/components/Common/SearchBlock/SearchForm/SearchForm';
import AnnouncementBar from 'js/components/Common/AnnouncementBar/AnnouncementBar';
import SearchButton from 'js/components/Common/SearchBlock/SearchButton/SearchButton';
import NavigationMenu from 'js/components/Common/Navigation/NavigationMenu/NavigationMenu';

import classes from './Header.module.pcss';
import UserProfile from './UserProfile/UserProfile';
import HeaderButtons from './HeaderButtons/HeaderButtons';

const Header = () => {
	const [isSticky, setSticky] = useState(false);
	const headerRef = useRef(null);
	const {
		headerHeight,
		setHeaderHeight,
		isCustomerLogged,
		currentUser,
		apiPath,
		isAnnouncementBar,
		setShowSearch
	} = useContext(CommonContext);
	const location = useLocation();
	const history = useHistory();
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);

	const searchIconClickHandler = useCallback(() => {
		setShowSearch(prev => !prev);
	}, [setShowSearch]);

	const scrollCallBack = useCallback(() => {
		const header: HTMLElement = document.getElementById('header');
		const sticky: number = header.offsetTop;
		window.pageYOffset > sticky ? setSticky(true) : setSticky(false);
	}, []);

	useEffect(() => {
		if (isCustomerLogged && searchParams.get('sourcePage')) {
			searchParams.delete('sourcePage');
			history.replace({
				search: searchParams.toString()
			});
		}
	}, [isCustomerLogged, searchParams, history]);

	useEffect(() => {
		if (isCustomerLogged !== null) {
			const header: HTMLElement = document.getElementById('header');
			const headerParent: HTMLElement = header.parentElement;
			const height = header?.offsetHeight;
			headerParent.style.marginTop = `${height}px`;
			setHeaderHeight(height);
		}

		window.addEventListener('scroll', scrollCallBack);
		return () => {
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, [scrollCallBack, setHeaderHeight, headerHeight, isCustomerLogged, isAnnouncementBar]);

	const logInHandler = useCallback(() => {
		if (location.pathname === '/') {
			const sourcePage = searchParams.get('sourcePage');
			return window.location.href = sourcePage ? `${apiPath}/auth?sourcePage=${sourcePage}` : `${apiPath}/auth`;
		}
		return window.location.href = `${apiPath}/auth?sourcePage=${location.pathname}`;
	}, [apiPath, location, searchParams]);

	const logOut = useCallback(() => {
		const symbol = location?.search ? '&' : '?';
		document.location.href = `${document.location.href}${symbol}logout`;
	}, [location]);

	const showDataByAuth = useMemo(() => {
		if (isCustomerLogged !== null && !isCustomerLogged) {
			return (
				<HeaderButtons
					logInCallback={logInHandler}/>
			);
		}
		if (isCustomerLogged !== null && isCustomerLogged) {
			const offsetTop = isSticky ? 25 : 35;
			return (
				<>
					<NavigationMenu location={'MAIN_MENU'} isMobile={true} levels={2} />
					<div className={classes.HeaderUserWrapper}>
						{location.pathname !== '/homepage' ? <SearchButton handler={searchIconClickHandler} /> : ''}
						<Notifications offsetTop={4} />
						<UserProfile offsetTop={offsetTop} userData={currentUser} logOutCallback={logOut}/>
					</div>
				</>
			);
		}
	}, [
		location,
		isCustomerLogged,
		logInHandler,
		isSticky, searchIconClickHandler, currentUser, logOut]);

	const setHeaderClasses = useCallback(() => {
		const headerClass = classes.Header;
		const stickyClass = isSticky ? classes.Sticky : '';
		const loginClass = isCustomerLogged ? classes.HeaderPostLogin : '';
		return `${headerClass} ${loginClass} ${stickyClass}`;
	}, [isCustomerLogged, isSticky]);

	return (
		<header ref={headerRef} id="header" className={setHeaderClasses()}>
			<AnnouncementBar/>
			<Container additionalClass={classes.TopWrap}>
				<div className={classes.HeaderWrapper}>
					<Logo footerLogo={false}/>
					{showDataByAuth}
				</div>
			</Container>
			{location.pathname !== '/homepage' ? <SearchForm headerRef={headerRef} /> : ''}
		</header>
	);
};

export default Header;
