import React, {MouseEventHandler, useCallback, useContext} from 'react';
import {CommonContext} from 'js/context/CommonContext/CommonContext';

import classes from './SearchButton.module.pcss';

export interface Props {
    handler: MouseEventHandler<HTMLDivElement>
}

const SearchButton = ({handler}: Props) => {
    const {showSearch} = useContext(CommonContext);
    const clickHandler = useCallback((e) => {
        handler(e);
    }, [handler]);
    return (
        <div data-show-search-form={true} className={classes.SearchButton} onClick={clickHandler}>
            <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8783 29.2917C24.6898 29.2917 30.2116 23.7698 30.2116 16.9583C30.2116 10.1468 24.6898 4.625 17.8783 4.625C11.0667 4.625 5.54492 10.1468 5.54492 16.9583C5.54492 23.7698 11.0667 29.2917 17.8783 29.2917Z" stroke={showSearch ? '#235AFB' : '#525962'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33.2941 32.3789L29.941 29.0239L26.5879 25.6689" stroke={showSearch ? '#235AFB' : '#525962'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};

export default SearchButton;
