import React, {useContext} from 'react';
import Profile from 'js/containers/Pages/Profile/Profile';
import SearchWrap from 'js/containers/Pages/Search/SearchWrap';
import {Switch, Route, Redirect, useLocation} from 'react-router';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import Page404Template from 'js/containers/Pages/404/Page404Template';

import useTemplateRouter from '../TemplateRouter/useTemplateRouter';
import useSinglePostRouter from '../PostTypeRouter/SinglePostRouter/useSinglePostRouter';

const Router = () => {
	const { pathname } = useLocation();
	const post = useSinglePostRouter();
	const template = useTemplateRouter();
	const {isCustomerLogged} = useContext(CommonContext);
	const staticLoggedOnlyPagesSlug = ['/search', '/profile', '/homepage'];

	return (
		<Switch>
			<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
			{isCustomerLogged === false && staticLoggedOnlyPagesSlug.includes(pathname) ? <Redirect to={'/'} /> : null}
			{isCustomerLogged && pathname === '/' ? <Redirect to={'/homepage'} /> : null}
			<Route exact key="searchRoute" path='/search' render={() => <SearchWrap />} />
			<Route exact key="myProfileRoute" path='/profile' render={() => <Profile />} />
			{template}
			{post}
			<Route exact key={'404Route'} component={Page404Template} />
		</Switch>
	);
};
export default Router;
